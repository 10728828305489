import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Configuration for calculation rules
const CALCULATION_RULES = {
    // Multipliers based on payment type
    paymentTypeMultipliers: {
        '健保': 1.0,  // Regular insurance - base rate
        '自費': 1.0,  // Self-paid
        '不申報': 0,  // Not reported to insurance
    },

    // Medicine category groups based on ID patterns
    medicineGroups: [
        {
            prefix: 'A',
            name: '抗生素類',
            multiplier: 1.0,
        },
        {
            prefix: 'B',
            name: '消炎止痛類',
            multiplier: 1.0,
        },
        // Add more medicine groups as needed
    ],
};

const MedicineSummary = ({
    medications = [],
    customRules = null,
    onSummaryCalculated = null
}) => {
    const [summary, setSummary] = useState({
        insuranceTotal: 0,        // 健保
        selfPaidTotal: 0,         // 自費
        groupTotals: {},
        paymentTypeTotals: {},
        totalCount: 0,
        totalMedicines: 0
    });

    // Use provided rules or fallback to default
    const rules = customRules || CALCULATION_RULES;

    useEffect(() => {
        // Skip calculation if no medications
        if (!medications || medications.length === 0) {
            const emptySummary = {
                insuranceTotal: 0,
                selfPaidTotal: 0,
                groupTotals: {},
                paymentTypeTotals: {},
                totalCount: 0,
                totalMedicines: 0
            };
            setSummary(emptySummary);
            
            // Call the callback with calculated values if provided
            if (onSummaryCalculated) {
                onSummaryCalculated(emptySummary);
            }
            return;
        }
    
        // Initialize calculation variables
        let insuranceTotal = 0;
        let selfPaidTotal = 0;
        let totalCount = 0;
        const groupTotals = {};
        const paymentTypeTotals = {};
    
        // Initialize group totals
        rules.medicineGroups.forEach(group => {
            groupTotals[group.name] = 0;
        });
    
        // Initialize payment type totals
        Object.keys(rules.paymentTypeMultipliers).forEach(type => {
            paymentTypeTotals[type] = 0;
        });
    
        // Calculate raw totals by group and payment type
        medications.forEach(medicine => {
            const paymentType = medicine.paymentType || '健保'; // Default to regular insurance
            const price = parseFloat(medicine.price) || 0;
            const total = parseInt(medicine.total) || 0;
            
            totalCount += total;
            
            // Calculate the medicine cost for this item
            const medicineCost = price * total;
            
            // Skip if no price or total
            if (medicineCost <= 0) return;
    
            // Add to payment type totals
            if (paymentTypeTotals[paymentType] !== undefined) {
                paymentTypeTotals[paymentType] += medicineCost;
            }
    
            // Separate calculation for insurance (健保) and self-paid (自費)
            if (paymentType === '自費') {
                selfPaidTotal += medicineCost;
            } else if (paymentType === '健保') {
                insuranceTotal += medicineCost;
            }
    
            // Check if this medicine belongs to any group
            rules.medicineGroups.forEach(group => {
                if (medicine.id && medicine.id.startsWith(group.prefix)) {
                    groupTotals[group.name] += medicineCost;
                }
            });
        });
    
        // Create the new summary
        const newSummary = {
            insuranceTotal,
            selfPaidTotal,
            groupTotals,
            paymentTypeTotals,
            totalCount,
            totalMedicines: medications.length
        };
    
        // Check if the summary actually changed before updating
        const hasChanged = JSON.stringify(summary) !== JSON.stringify(newSummary);
        
        if (hasChanged) {
            // Update summary state
            setSummary(newSummary);
            
            // Call the callback with calculated values if provided
            if (onSummaryCalculated) {
                onSummaryCalculated(newSummary);
            }
        }
    }, [medications, customRules]);

    return (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-3">藥品統計</h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left column: Totals */}
                <div>
                    <div className="mb-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">藥品總覽</h4>
                        <div className="bg-white p-3 rounded border border-gray-200">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-800">總藥品數量</span>
                                <span className="font-medium">{summary.totalMedicines} 種</span>
                            </div>

                            {/* Total after adjustments */}
                            <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200">
                                <span className="text-gray-800 font-medium">健保藥品總費用</span>
                                <span className="font-bold text-lg text-green-600">${summary.insuranceTotal.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right column: Breakdowns */}
                <div>
                    {/* Payment type totals */}
                    <div>
                        <h4 className="text-sm font-medium text-gray-700 mb-2">給付類型統計</h4>
                        <div className="bg-white p-3 rounded border border-gray-200">
                            {summary.paymentTypeTotals['健保'] > 0 && (
                                <div className="flex justify-between items-center mb-1">
                                    <span className="text-gray-800">健保</span>
                                    <span className="font-medium">${summary.paymentTypeTotals['健保'].toFixed(2)}</span>
                                </div>
                            )}

                            {summary.paymentTypeTotals['自費'] > 0 && (
                                <div className="flex justify-between items-center mb-1">
                                    <span className="text-gray-800">自費</span>
                                    <span className="font-medium">${summary.paymentTypeTotals['自費'].toFixed(2)}</span>
                                </div>
                            )}
                            
                            {/* Display total */}
                            <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200">
                                <span className="text-gray-800 font-medium">藥品總費用</span>
                                <span className="font-bold text-green-600">
                                    ${(summary.insuranceTotal + summary.selfPaidTotal).toFixed(2)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MedicineSummary.propTypes = {
    medications: PropTypes.array,
    customRules: PropTypes.object,
    onSummaryCalculated: PropTypes.func
};

export default MedicineSummary;