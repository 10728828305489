import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc, setDoc, orderBy, limit, getDocs } from 'firebase/firestore';
import { Tab } from '@headlessui/react';
import moment from 'moment';
import { db } from './Firebase';
import { toast } from 'react-hot-toast';
import { getTaiwanTimeString } from './TaiwanTimeStamp';

const PatientList = forwardRef(({
  selectedDoctor,
  targetClinicId,
  onPatientSelect,
  searchQuery = '',
  onSearchChange,
  selectedPatientId
}, ref) => {
  const [checkedInPatients, setCheckedInPatients] = useState([]);
  const [completedPatients, setCompletedPatients] = useState([]);
  const [localSelectedPatientId, setLocalSelectedPatientId] = useState(selectedPatientId);
  const [isLoading, setIsLoading] = useState(true);

  // Sync external selected state
  useEffect(() => {
    setLocalSelectedPatientId(selectedPatientId);
  }, [selectedPatientId]);

  const getNextPatient = (currentPatientId) => {
    // 找到當前病患在已報到列表中的索引
    const currentIndex = checkedInPatients.findIndex(p => p.id === currentPatientId);
    
    // 如果找到當前病患，返回列表中的下一個病患
    if (currentIndex !== -1 && currentIndex < checkedInPatients.length - 1) {
      return checkedInPatients[currentIndex + 1];
    }
    
    // 如果沒有下一個病患，返回null
    return null;
  };

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    revertSelection: (patientId) => {
      setLocalSelectedPatientId(patientId);
    },
    getNextPatient
  }));

  // Handle patient selection
  const handlePatientSelect = async (patient) => {
    if (!patient || localSelectedPatientId === patient.id) return;
    
    try {
      let medicalRecordId = patient.medicalRecordId;
      let medicalRecord;
  
      if (medicalRecordId) {
        // Get existing medical record
        const recordDoc = await getDoc(doc(db, 'MedicalRecords', medicalRecordId));
        if (recordDoc.exists()) {
          medicalRecord = recordDoc.data();
        } else {
          console.error('Medical record not found:', medicalRecordId);
          medicalRecordId = null; // Reset if record doesn't exist
        }
      }
  
      if (!medicalRecordId) {
        // Create new medical record
        medicalRecordId = `${patient.id}_${getTaiwanTimeString()}`;
        medicalRecord = {
          patientId: patient.id,
          patientName: patient.userName,
          patientTwId: patient.userTWId,
          clinicId: targetClinicId,
          doctorId: selectedDoctor.doctorId,
          doctorName: selectedDoctor.doctorName,
          status: '進行中',
          createdAt: getTaiwanTimeString(),
          lastUpdated: getTaiwanTimeString(),
          complains: '',
          diagnosis: '',
          medications: [],
          imageUrls: [],
          diagnosisCodes: []
        };
  
        // Create new record
        const recordRef = doc(db, 'MedicalRecords', medicalRecordId);
        await setDoc(recordRef, medicalRecord);
  
        // Update appointment with medical record ID
        const appointmentRef = doc(db, 'Appointment', patient.id);
        await setDoc(appointmentRef, {
          medicalRecordId: medicalRecordId
        }, { merge: true });
      }
  
      // Update patient object with medical record data
      const updatedPatient = {
        ...patient,
        medicalRecordId,
        medicalRecord
      };
  
      // Call parent handler with updated patient data
      if (!onPatientSelect(updatedPatient)) {
        setLocalSelectedPatientId(localSelectedPatientId);
      } else {
        setLocalSelectedPatientId(patient.id);
      }
  
    } catch (error) {
      console.error('Error handling patient selection:', error);
      toast.error('選擇病患時發生錯誤');
    }
  };

  // Filter patients based on search query
  const filterPatients = useCallback((patients, query) => {
    if (!query && !patients) return [];

    const sortedPatients = [...patients].sort((a, b) => {
      const numA = parseInt(a?.number || '999999', 10);
      const numB = parseInt(b?.number || '999999', 10);
      return numA - numB;
    });

    if (!query) return sortedPatients;

    return sortedPatients.filter(patient => {
      const userName = (patient?.userName ?? '').toLowerCase();
      const userTWId = (patient?.userTWId ?? '').toLowerCase();
      const searchLower = query.toLowerCase();

      return userName.includes(searchLower) || userTWId.includes(searchLower);
    });
  }, []);

  // Real-time patient updates
  useEffect(() => {
    let unsubscribe = () => { };

    const setupRealtimeUpdates = async () => {
      if (!selectedDoctor?.doctorId || !targetClinicId) {
        setIsLoading(false);
        return;
      }

      const today = moment().format('YYYYMMDD');

      try {
        const q = query(
          collection(db, 'Appointment'),
          where('ownedClinicId', '==', targetClinicId),
          where('doctorId', '==', selectedDoctor.doctorId),
          where('date', '==', today)
        );

        let timeoutId;
        unsubscribe = onSnapshot(q, (snapshot) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          timeoutId = setTimeout(() => {
            const patients = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));

            const checkedIn = filterPatients(
              patients.filter(p => p?.status === '已報到'),
              searchQuery
            );
            const completed = filterPatients(
              patients.filter(p => p?.status === '已看診'),
              searchQuery
            );

            setCheckedInPatients(checkedIn);
            setCompletedPatients(completed);
            setIsLoading(false);
          }, 100);
        }, (error) => {
          console.error('Error in patient snapshot:', error);
          toast.error('讀取病患資料失敗');
          setIsLoading(false);
        });

      } catch (error) {
        console.error('Error setting up patient snapshot:', error);
        setIsLoading(false);
      }
    };

    setupRealtimeUpdates();
    return () => {
      unsubscribe();
    };
  }, [selectedDoctor, searchQuery, targetClinicId, filterPatients]);

  const PatientTable = ({ patients }) => (
    <div className="mt-2 max-h-[calc(100vh-300px)] overflow-y-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 sticky top-0">
          <tr>
            <th className="px-2 py-2 text-s font-medium text-gray-500">叫號</th>
            <th className="px-2 py-2 text-s font-medium text-gray-500">姓名</th>
            <th className="px-2 py-2 text-s font-medium text-gray-500">年齡</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {patients.length > 0 ? (
            patients.map((patient, index) => (
              <tr
                key={patient.id}
                onClick={() => handlePatientSelect(patient)}
                className={`cursor-pointer ${localSelectedPatientId === patient.id
                  ? 'bg-blue-300'
                  : index % 2 === 0
                    ? 'bg-gray-100'
                    : 'bg-white'
                  } hover:bg-blue-100`}
              >
                <td className="px-2 py-2 text-lg">{patient.number || '-'}</td>
                <td className="px-2 py-2 text-lg">{patient.userName || '-'}</td>
                <td className="px-2 py-2 text-lg">
                  {patient.birthday
                    ? moment().diff(moment(patient.birthday), 'years')
                    : '-'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="px-2 py-4 text-center text-gray-500">
                {isLoading ? '載入中...' : '無相符的病患資料'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="space-y-4">
      <div>
        <input
          type="text"
          placeholder="搜尋姓名或身分證..."
          value={searchQuery}
          onChange={e => onSearchChange?.(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2 text-sm font-medium leading-5 relative
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            已報到
            {checkedInPatients.length > 0 && (
              <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white rounded-full w-5 h-5 text-xs flex items-center justify-center">
                {checkedInPatients.length}
              </span>
            )}
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2 text-sm font-medium leading-5 relative
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            已看診
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <PatientTable patients={checkedInPatients} />
          </Tab.Panel>
          <Tab.Panel>
            <PatientTable patients={completedPatients} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
});

export default PatientList;