import React, { useState, useEffect, useRef } from 'react';
import { ref, getMetadata, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { storage, db } from './Firebase';
import Papa from 'papaparse';
import { toast } from 'react-hot-toast';
import Fuse from 'fuse.js';
import { FiRefreshCw, FiSearch, FiX, FiTrash2 } from 'react-icons/fi';
import debounce from 'lodash/debounce';

const DB_NAME = 'ICDCodesDB';
const DB_VERSION = 1;
const STORE_NAME = 'icdCodes';
const META_STORE_NAME = 'metadataICD';

// Database initialization function
const initializeDatabase = () => {
  return new Promise((resolve, reject) => {
    let openRequest;
    try {
      openRequest = indexedDB.open(DB_NAME, DB_VERSION);
    } catch (error) {
      console.error('Failed to open IndexedDB:', error);
      reject(error);
      return;
    }

    openRequest.onerror = (event) => {
      console.error("Database error:", event.target.error);
      reject(event.target.error);
    };

    openRequest.onblocked = (event) => {
      console.warn("Database blocked, please close other tabs");
      // Try to close the database gracefully
      if (openRequest.result) {
        openRequest.result.close();
      }
    };

    openRequest.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create stores if they don't exist
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        store.createIndex('chineseName', 'chineseName', { unique: false });
        store.createIndex('englishName', 'englishName', { unique: false });
      }

      if (!db.objectStoreNames.contains(META_STORE_NAME)) {
        db.createObjectStore(META_STORE_NAME, { keyPath: 'key' });
      }
    };

    openRequest.onsuccess = (event) => {
      const db = event.target.result;

      // Handle database closing
      db.onversionchange = () => {
        db.close();
        console.log('Database is outdated, please reload the page.');
        toast.error('資料庫版本已更新，請重新載入頁面');
      };

      resolve(db);
    };
  });
};

const ICDCodeSelector = ({
  onSelect,
  clinicId,
  doctorId,
  isActive = false,            // New prop
  onDropdownOpen = () => { },   // New prop
  onDropdownClose = () => { },  // New prop
  zIndex = 10                  // New prop
}) => {
  // State Management
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [frequentCodes, setFrequentCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dbStatus, setDbStatus] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [showFrequentCodes, setShowFrequentCodes] = useState(false);

  // Refs
  const fuseRef = useRef(null);
  const localDBRef = useRef(null);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const selectedItemRef = useRef(null);

  // Initialize IndexedDB
  useEffect(() => {
    let isMounted = true;
    let dbInstance = null;

    const initDB = async () => {
      try {
        setIsLoading(true);
        dbInstance = await initializeDatabase();

        if (isMounted) {
          localDBRef.current = dbInstance;
          await checkForUpdates(); // Check for updates after initializing the database
        }
      } catch (error) {
        console.error('Database initialization failed:', error);
        if (isMounted) {
          toast.error('初始化資料庫失敗，請重新載入頁面');
          setDbStatus('資料庫錯誤');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    initDB();

    // Cleanup function
    return () => {
      isMounted = false;
      if (dbInstance) {
        dbInstance.close();
      }
    };
  }, []);

  // Load frequent codes on mount
  useEffect(() => {
    loadFrequentCodesFromFirebase();
  }, [clinicId, doctorId]);

  // Add scroll detection
  useEffect(() => {
    if (suggestionsRef.current) {
      setIsScrollable(
        suggestionsRef.current.scrollHeight > suggestionsRef.current.clientHeight
      );
    }
  }, [suggestions]);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedItemRef.current && suggestionsRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedIndex]);

  // Firebase Interaction Methods
  const loadFrequentCodesFromFirebase = async () => {
    if (!clinicId || !doctorId) return;

    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists()) {
        const clinicData = clinicDoc.data();
        const doctors = clinicData.doctors || [];
        const currentDoctor = doctors.find(d => d.doctorId === doctorId);

        if (currentDoctor?.frequentICDCodes) {
          setFrequentCodes(currentDoctor.frequentICDCodes);
        } else {
          const localCodes = localStorage.getItem(`frequentICDCodes_${doctorId}`);
          if (localCodes) {
            setFrequentCodes(JSON.parse(localCodes));
          }
        }
      }
    } catch (error) {
      console.error('Error loading frequent codes:', error);
      const localCodes = localStorage.getItem(`frequentICDCodes_${doctorId}`);
      if (localCodes) {
        setFrequentCodes(JSON.parse(localCodes));
      }
    }
  };

  const updateFrequentCodes = async (code) => {
    try {
      const newFrequentCodes = [code, ...frequentCodes]
        .filter((c, index, self) =>
          index === self.findIndex(m => m.id === c.id)
        )
        .slice(0, 20);

      setFrequentCodes(newFrequentCodes);
      localStorage.setItem(
        `frequentICDCodes_${doctorId}`,
        JSON.stringify(newFrequentCodes)
      );

      if (clinicId && doctorId) {
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctors = clinicData.doctors || [];
          const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

          if (doctorIndex !== -1) {
            doctors[doctorIndex] = {
              ...doctors[doctorIndex],
              frequentICDCodes: newFrequentCodes
            };

            await updateDoc(clinicRef, { doctors });
          }
        }
      }
    } catch (error) {
      console.error('Error updating frequent codes:', error);
      toast.error('更新常用代碼失敗');
    }
  };

  // Database Management Methods
  const downloadAndProcessICDCodes = async () => {
    try {
      const storageRef = ref(storage, 'icd-data/icd10cm-codes.csv');
      const url = await getDownloadURL(storageRef);
      const metadata = await getMetadata(storageRef);

      const response = await fetch(url);
      const text = await response.text();

      const results = Papa.parse(text, {
        header: true,
        skipEmptyLines: true
      });

      const uniqueIds = new Set();
      const codes = results.data
        .filter(row => {
          if (!row.icd10cm || uniqueIds.has(row.icd10cm)) return false;
          if (uniqueIds.has(row.icd10cm)) return false;
          uniqueIds.add(row.icd10cm);
          return true;
        })
        .map(row => ({
          id: row.icd10cm,
          chineseName: row.chineseName,
          englishName: row.englishName
        }));

      await saveICDCodesToDB(codes, metadata.customMetadata);
      initializeFuse(codes);
      setDbStatus(`已更新 ${codes.length} 筆代碼資料`);
    } catch (error) {
      console.error('Error downloading ICD codes:', error);
      throw error;
    }
  };

  const saveICDCodesToDB = async (codes, metadata) => {
    if (!localDBRef.current) {
      throw new Error('Database not initialized');
    }

    const BATCH_SIZE = 1000;
    const totalBatches = Math.ceil(codes.length / BATCH_SIZE);

    try {
      // Clear existing data
      const clearTransaction = localDBRef.current.transaction([STORE_NAME], 'readwrite');
      const store = clearTransaction.objectStore(STORE_NAME);

      await new Promise((resolve, reject) => {
        const clearRequest = store.clear();
        clearRequest.onsuccess = resolve;
        clearRequest.onerror = () => reject(clearRequest.error);
      });

      // Insert new data in batches
      for (let i = 0; i < totalBatches; i++) {
        const start = i * BATCH_SIZE;
        const end = Math.min(start + BATCH_SIZE, codes.length);
        const batch = codes.slice(start, end);

        await new Promise((resolve, reject) => {
          const batchTransaction = localDBRef.current.transaction([STORE_NAME], 'readwrite');
          const store = batchTransaction.objectStore(STORE_NAME);

          batchTransaction.oncomplete = resolve;
          batchTransaction.onerror = (event) => reject(event.target.error);

          batch.forEach(code => {
            if (code.id) {
              store.put({
                ...code,
                updatedAt: new Date().toISOString()
              });
            }
          });
        });

        setDbStatus(`已儲存 ${end} / ${codes.length} 筆資料`);
      }

      // Update metadata
      const metaTransaction = localDBRef.current.transaction([META_STORE_NAME], 'readwrite');
      const metaStore = metaTransaction.objectStore(META_STORE_NAME);

      await new Promise((resolve, reject) => {
        const metaRequest = metaStore.put({
          key: 'version',
          checksum: metadata.fileChecksum,
          updatedAt: new Date().toISOString(),
          totalRecords: codes.length
        });

        metaRequest.onsuccess = resolve;
        metaRequest.onerror = () => reject(metaRequest.error);
      });

    } catch (error) {
      console.error('Error saving to IndexedDB:', error);
      throw new Error('儲存資料失敗: ' + error.message);
    }
  };

  // Get local metadata from IndexedDB
  const getLocalMetadata = async () => {
    return new Promise((resolve, reject) => {
      if (!localDBRef.current) {
        reject(new Error('Database not initialized'));
        return;
      }

      const transaction = localDBRef.current.transaction([META_STORE_NAME], 'readonly');
      const store = transaction.objectStore(META_STORE_NAME);
      const request = store.get('version');

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  };

  // Load codes from local DB
  const loadLocalICDCodes = async () => {
    return new Promise((resolve, reject) => {
      const transaction = localDBRef.current.transaction(['icdCodes'], 'readonly');
      const store = transaction.objectStore('icdCodes');
      const request = store.getAll();

      request.onsuccess = () => {
        initializeFuse(request.result);
        const lastUpdateCheck = localStorage.getItem('lastUpdateCheck');
        const lastUpdateTime = lastUpdateCheck ? new Date(parseInt(lastUpdateCheck, 10)).toLocaleString() : '無記錄';
        setDbStatus(`資料最後更新時間：${lastUpdateTime}`);
        resolve(request.result);
      };
      request.onerror = () => reject(request.error);
    });
  };

  const checkForUpdates = async () => {
    try {
      setIsLoading(true);
      setDbStatus('檢查更新中...');

      const lastUpdateCheck = localStorage.getItem('lastUpdateCheck');
      const currentTime = Date.now();

      // Check if local data exists
      const localMetadata = await getLocalMetadata();

      // If no local data exists, download the data immediately
      if (!localMetadata) {
        setDbStatus('下載資料中...');
        await downloadAndProcessICDCodes();
        localStorage.setItem('lastUpdateCheck', currentTime.toString());
        return;
      }

      // If 24 hours have passed since the last update check, check for updates
      if (!lastUpdateCheck || (currentTime - parseInt(lastUpdateCheck, 10)) > 24 * 60 * 60 * 1000) {
        const storageRef = ref(storage, 'icd-data/icd-codes.csv');
        const metadata = await getMetadata(storageRef);

        // Compare the checksum of the local data with the remote data
        if (localMetadata.checksum !== metadata.customMetadata.fileChecksum) {
          setDbStatus('發現新版本，更新中...');
          await downloadAndProcessICDCodes();
        } else {
          setDbStatus('資料已是最新版本');
        }

        // Update the last update check time
        localStorage.setItem('lastUpdateCheck', currentTime.toString());
      } else {
        setDbStatus('載入本地資料...');
        await loadLocalICDCodes();
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
      toast.error('檢查更新失敗，使用本地資料');
      await loadLocalICDCodes();
    } finally {
      setIsLoading(false);
    }
  };

  const refreshDatabase = async () => {
    if (isRefreshing) return;

    try {
      setIsRefreshing(true);
      setDbStatus('正在更新資料...');
      await downloadAndProcessICDCodes();
      toast.success('ICD 代碼資料庫已更新');
    } catch (error) {
      console.error('Error refreshing database:', error);
      toast.error('更新資料庫失敗');
    } finally {
      setIsRefreshing(false);
    }
  };

  // Search and Selection Methods
  const initializeFuse = (codes) => {
    fuseRef.current = new Fuse(codes, {
      keys: ['id', 'englishName', 'chineseName'],
      threshold: 0.3,
      includeScore: true,
      findAllMatches: true,
      ignoreLocation: true
    });
  };

  const handleSearch = debounce((term) => {
    if (!term) {
      setSuggestions(frequentCodes.slice(0, 10));
      return;
    }

    if (fuseRef.current) {
      const results = fuseRef.current.search(term)
        .slice(0, 10)
        .map(result => result.item);
      setSuggestions(results);
      setSelectedIndex(0);
    }
  }, 150);

  const handleSelect = async (code) => {
    if (!code) return;
  
    try {
      await updateFrequentCodes(code);
      onSelect?.(code);
      setSelectedCodes(prev => [...prev, code]);
      setSearchTerm(''); // Clear the search term
      setSuggestions(frequentCodes.slice(0, 10)); // Reset suggestions to frequent codes
      setShowSuggestions(true); // Keep suggestions visible
      
      // Maintain focus on the input field
      inputRef.current?.focus();
      
      toast.success(`已選擇: ${code.chineseName}`);
    } catch (error) {
      console.error('Error selecting code:', error);
      toast.error('新增代碼失敗');
    }
  };

  // UI Interaction Methods
  const handleFocus = () => {
    onDropdownOpen(); // Notify parent that this dropdown is opening

    if (!searchTerm && frequentCodes.length > 0) {
      setShowFrequentCodes(true);
      setSuggestions(frequentCodes.slice(0, 10));
    }
    setShowSuggestions(true);
  };

  const handleCloseSuggestions = () => {
    onDropdownClose(); // Notify parent that this dropdown is closing

    setShowSuggestions(false);
    setShowFrequentCodes(false);
    setSearchTerm('');
    setSuggestions([]);
  };

  const handleKeyDown = (e) => {
    if (!suggestions.length) return;
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (suggestions[selectedIndex]) {
          handleSelect(suggestions[selectedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        handleCloseSuggestions();
        break;
      default:
        // For any other key, we ensure suggestions remain visible
        if (!showSuggestions) {
          setShowSuggestions(true);
        }
        break;
    }
  };

  return (
    <div className="space-y-4">
      {/* Search Input Section */}
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <div className="relative">
            <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value);
              }}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder="搜尋 ICD 代碼或名稱..."
              className="w-full pl-10 pr-20 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              disabled={isLoading}
            />
            {(showSuggestions || showFrequentCodes) && (
              <button
                onClick={handleCloseSuggestions}
                className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <FiX className="w-4 h-4" />
              </button>
            )}
            <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-sm">
              {dbStatus}
            </div>
          </div>
        </div>
        <button
          onClick={refreshDatabase}
          disabled={isRefreshing || isLoading}
          className={`p-2 rounded-lg border border-gray-300 hover:bg-gray-50 
              ${(isRefreshing || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          title="更新 ICD 代碼資料庫"
        >
          <FiRefreshCw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
        </button>
      </div>

      {/* Suggestions Section */}
      {showSuggestions && suggestions.length > 0 && (
        <div className="relative">
          <button
            onClick={handleCloseSuggestions}
            className="absolute -top-2 -right-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
            title="關閉建議"
          >
            <FiX className="w-4 h-4 text-gray-600" />
          </button>
          <div
            ref={suggestionsRef}
            className="absolute z-40 w-full mt-1 bg-white border border-gray-200 
                     rounded-lg shadow-lg max-h-60 overflow-y-auto 
                     scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
            style={{ zIndex: zIndex }}
          >
            {showFrequentCodes && (
              <div className="px-4 py-2 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                <span className="text-sm font-medium text-gray-600">最近使用的代碼</span>
                <button
                  onClick={() => setShowFrequentCodes(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <FiX className="w-4 h-4" />
                </button>
              </div>
            )}

            {suggestions.map((code, index) => (
              <div
                key={code.id}
                ref={index === selectedIndex ? selectedItemRef : null}
                onClick={() => handleSelect(code)}
                className={`w-full px-4 py-2 text-left cursor-pointer hover:bg-gray-50 
                           flex flex-col border-b border-gray-100
                           ${index === selectedIndex ? 'bg-blue-50' : ''}`}
              >
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-blue-600">{code.id}</span>
                  <span className="text-sm text-gray-500">|</span>
                  <span className="font-medium">{code.englishName} {code.chineseName}</span>
                </div>
              </div>
            ))}
          </div>

          {/* Scroll indicator */}
          {isScrollable && (
            <div className="absolute right-0 top-1 bottom-1 w-1 z-50">
              <div className="h-full w-full bg-gray-200 rounded-r-lg">
                <div
                  className="w-full bg-gray-400 rounded-r-lg opacity-50 
                             transition-all duration-300
                             hover:opacity-75 hover:w-1.5"
                  style={{
                    height: '30%',
                    transform: `translateY(${(selectedIndex / suggestions.length) * 70}%)`
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ICDCodeSelector;
