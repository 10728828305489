import React, { useState, useRef } from 'react';
import Modal from './Modal';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { FiPrinter, FiX } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

const ConsultationSummaryModal = ({
    isOpen,
    onClose,
    record,
    patient,
    clinicName,
    onPrint
}) => {
    const [isPrinting, setIsPrinting] = useState(false);
    const printRef = useRef();

    const getGender = (idNumber) => {
        if (!idNumber || idNumber.length < 2) return '未知';
        // Taiwanese ID numbers: 1st digit is 1 for male, 2 for female
        const secondChar = idNumber.charAt(1);
        return secondChar === '1' ? '男' : secondChar === '2' ? '女' : '未知';
    };

    // Sort medications: 一般門診 first, then 慢性處方簽
    const sortedMedications = record?.medications ? [...record.medications].sort((a, b) => {
        const typeA = a.prescriptionType === 'chronic' ? 1 : 0;
        const typeB = b.prescriptionType === 'chronic' ? 1 : 0;
        return typeA - typeB;
    }) : [];

    const handlePrint = useReactToPrint({
        contentRef: printRef,
        onBeforeprint: () => setIsPrinting(true),
        onAfterPrint: () => {
            setIsPrinting(false);
            if (onPrint) onPrint();
        }
    });

    if (!record || !patient) return null;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={onClose}
            title="看診總結 (將列印為A5橫式尺寸)"
            width="max-w-5xl"
        >
            <div className="printable-content mb-16">
                {/* Print styles - these will only apply during printing */}
                <style type="text/css" media="print">
                    {`
                    @page {
                      size: A5 landscape; /* A5 horizontal layout */
                      margin: 0;
                    }
                    body {
                      margin: 0;
                      padding: 0;
                      font-family: 'Microsoft JhengHei', Arial, sans-serif;
                    }
                    .print-container {
                      width: 100%;
                      height: 100%;
                    }
                    .print-page {
                      page-break-after: always;
                      display: flex;
                      flex-direction: row;
                      width: 100%;
                      height: 100%;
                    }
                    .print-section {
                      margin-bottom: 0.3cm;
                    }
                    .print-column {
                      width: 50%;
                      height: 100%;
                      box-sizing: border-box;
                      position: relative;
                      padding: 0.3cm;
                    }
                    .print-column:first-child {
                      border-right: 1px dashed #999;
                    }
                    .print-header {

                      padding-bottom: 0.1cm;
                      margin-bottom: 0.1cm;
                      font-size: 10pt;
                    }
                    .print-table {
                      width: 100%;
                      border-collapse: collapse;
                      font-size: 8pt;
                    }
                    .print-table th, .print-table td {
                      border: 1px solid #000;
                      padding: 0.1cm;
                      text-align: left;
                    }
                    .print-table th {
                      background-color: #fff;
                      font-weight: bold;
                    }
                    .med-table th, .med-table td {
                      padding: 0.01cm;
                    }
                    .smaller-text {
                      font-size: 8pt;
                    }
                    .tiny-text {
                      font-size: 7pt;
                    }
                    .action-buttons {
                      display: none; /* Hide buttons when printing */
                    }
                    `}
                </style>

                {/* Printable content */}
                <div
                    ref={printRef}
                    className="print-container border border-gray-300 rounded-lg overflow-hidden"
                    style={{ width: '100%', minHeight: '300px' }}
                >
                    {/* A5 Landscape Page with two A6 portrait sections side by side */}
                    <div className="print-page">
                        {/* Left Column - Prescription (A6 Portrait) */}
                        <div className="print-column">
                            {/* Header */}
                            <div className="print-header">
                                <div style={{ fontWeight: 'bold', fontSize: '15pt', textAlign: 'center' }}>
                                    {clinicName} 病歷
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>姓名: {patient.userName}</div>
                                        <div className="smaller-text">
                                            <div>身分證：{patient.userTWId}</div>
                                            <div>生日：{patient.birthday || '未知'}</div>
                                            <div>年齡/性別：{patient.birthday
                                                ? moment().diff(moment(patient.birthday), 'years')
                                                : '未知'}歲 {getGender(patient.userTWId)}
                                            </div>
                                            <div>看診序號：OOO</div>
                                        </div>
                                    </div>
                                    <div className="smaller-text" style={{ textAlign: 'center' }}>
                                        <div>就醫日期：{moment().format('YYYY/MM/DD')}</div>
                                        <div>醫師：{record.doctorName}</div>
                                        <div>藥師：{record.doctorName}</div>
                                        <div>病歷序號：OOO</div>
                                        <div>科別：OOO</div>
                                        <div>就醫序號：OOO</div>
                                    </div>
                                </div>
                            </div>

                            {/* Clinical Information */}
                            <div className="print-section smaller-text">
                                <div style={{ marginBottom: '0.2cm' }}>
                                    <div>主訴：{record.complains || '無'} </div>
                                    <div>PE：  {record.pe || '無'}</div>
                                </div>

                                <div style={{ marginBottom: '0.2cm' }}>

                                </div>

                                <div style={{ marginBottom: '0.2cm' }}>
                                    <strong>診斷：</strong>
                                    {record.diagnosisCodes?.length > 0 ? (
                                        <div>
                                            {record.diagnosisCodes.map((code, index) => {
                                                const details = record.diagnosisDetails?.[code] || {};
                                                return (
                                                    <div key={index} className="tiny-text">
                                                        {code} {details.englishName || ''} {details.chineseName || ''}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : '無診斷'}
                                </div>

                                {/* Medications */}
                                <div className="print-section">
                                    <div><strong>藥令：</strong></div>
                                    {sortedMedications.length > 0 ? (
                                        <table className="med-table">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '25%' }}>藥品名稱</th>
                                                    <th style={{ width: '12%' }}>劑量</th>
                                                    <th style={{ width: '12%' }}>頻率</th>
                                                    <th style={{ width: '10%' }}>天數</th>
                                                    <th style={{ width: '12%' }}>類型</th>
                                                    <th style={{ width: '9%' }}>調劑次數</th>
                                                    <th style={{ width: '10%' }}>總量</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedMedications.map((med, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {med.chineseName}<br />
                                                            <span className="tiny-text">{med.englishName}</span>
                                                        </td>
                                                        <td>{med.dosage}</td>
                                                        <td>{med.frequency}</td>
                                                        <td>{med.duration}</td>
                                                        <td>{med.prescriptionType === 'chronic' ? '慢性處方簽' : '一般門診'}</td>
                                                        <td>{med.prescriptionType === 'chronic' ? (record.refillCount || '0') : '0'}</td>
                                                        <td>{med.total}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div>無用藥記錄</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Receipt (A6 Portrait) */}
                        <div className="print-column">
                            {/* Receipt Header */}
                            <div className="print-header">
                                <div style={{ fontWeight: 'bold', fontSize: '15pt', textAlign: 'center', marginBottom: '0.2cm' }}>
                                    {clinicName} 收據
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>姓名: {patient.userName}</div>
                                        <div className="smaller-text">
                                            <div>身分證：{patient.userTWId}</div>
                                            <div>生日：{patient.birthday || '未知'}</div>
                                            <div>年齡/性別：{patient.birthday
                                                ? moment().diff(moment(patient.birthday), 'years')
                                                : '未知'}歲 {getGender(patient.userTWId)}
                                            </div>
                                            <div>看診序號：OOO</div>
                                        </div>
                                    </div>
                                    <div className="smaller-text" style={{ textAlign: 'center' }}>
                                        <div>就醫日期：{moment().format('YYYY/MM/DD')}</div>
                                        <div>醫師：{record.doctorName}</div>
                                        <div>藥師：{record.doctorName}</div>
                                        <div>病歷序號：OOO</div>
                                        <div>科別：OOO</div>
                                        <div>就醫序號：OOO</div>
                                    </div>
                                </div>
                            </div>

                            {/* Point Summary - Text Format */}
                            <div className="print-section smaller-text">

                                {/* First row of costs */}
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>診察</div>
                                            <div>_____</div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>藥費</div>
                                            <div>_____</div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>治療</div>
                                            <div>_____</div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>藥事</div>
                                            <div>_____</div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>合計</div>
                                            <div>_____</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div>健保申請</div>
                                        <div>{record.adjustedTotal?.toFixed(2) || '0.00'}</div>
                                    </div>
                                </div>

                                {/* Second row of costs */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.1cm' }}>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>掛號費</div>
                                            <div>_____</div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>部分負擔</div>
                                            <div>_____</div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '16%' }}>
                                            <div>自費</div>
                                            <div>{record.selfPaidTotal?.toFixed(2) || '0.00'}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div>本次實收</div>
                                        <div>{(record.selfPaidTotal || 0).toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>

                            {/* Medications Table on Receipt Side */}
                            <div className="print-section smaller-text">
                                {sortedMedications.length > 0 ? (
                                    <table className="med-table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>藥品名稱</th>
                                                <th style={{ width: '12%' }}>劑量</th>
                                                <th style={{ width: '12%' }}>頻率</th>
                                                <th style={{ width: '10%' }}>天數</th>
                                                <th style={{ width: '12%' }}>類型</th>
                                                <th style={{ width: '9%' }}>調劑次數</th>
                                                <th style={{ width: '10%' }}>總量</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedMedications.map((med, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {med.chineseName}<br />
                                                        <span className="tiny-text">{med.englishName}</span>
                                                    </td>
                                                    <td>{med.dosage}</td>
                                                    <td>{med.frequency}</td>
                                                    <td>{med.duration}</td>
                                                    <td>{med.prescriptionType === 'chronic' ? '慢性處方簽' : '一般門診'}</td>
                                                    <td>{med.prescriptionType === 'chronic' ? (record.refillCount || '0') : '0'}</td>
                                                    <td>{med.total}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>無用藥記錄</div>
                                )}
                            </div>

                            {/* Patient Signature */}
                            <div className="smaller-text">
                                院所地址: 台中市西屯區福安XX街XX號 電話: 05-1234567
                            </div>
                            <div className="smaller-text">
                                藥品請存放在陰涼處，收據請妥善保存遺失恕不補發
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Action Buttons - Fixed at bottom with clear separation from content */}
            <div className="action-buttons fixed bottom-0 left-0 right-0 bg-white shadow-md print:hidden">
                <div className="container mx-auto px-6 py-4 max-w-5xl flex justify-end space-x-4 border-t">
                    <button
                        onClick={onClose}
                        disabled={isPrinting}
                        className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                        取消
                    </button>
                    <button
                        onClick={handlePrint}
                        disabled={isPrinting}
                        className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center space-x-2 transition-colors"
                    >
                        {isPrinting ? (
                            <div className="flex items-center space-x-2">
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                                <span>處理中...</span>
                            </div>
                        ) : (
                            <>
                                <FiPrinter className="w-5 h-5" />
                                <span>列印並完成看診</span>
                            </>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ConsultationSummaryModal;