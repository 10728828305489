import React, { useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import { toast } from 'react-hot-toast';
import Modal from './Modal';
import { FiSearch, FiX, FiTrash2, FiSettings } from 'react-icons/fi';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';

const SelectMedicalServiceTemplateModal = ({
  isOpen,
  closeModal,
  onSelect,
  clinicId,
  doctorId
}) => {
  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  // Load templates when modal opens
  React.useEffect(() => {
    const loadTemplates = async () => {
      if (!clinicId || !doctorId) return;

      try {
        setIsLoading(true);
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctor = clinicData.doctors?.find(d => d.doctorId === doctorId);
          if (doctor?.serviceTemplates) {
            setTemplates(doctor.serviceTemplates);
          }
        }
      } catch (error) {
        console.error('Error loading templates:', error);
        toast.error('載入模板失敗');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      loadTemplates();
    }
  }, [isOpen, clinicId, doctorId]);

  // Delete template
  const handleDelete = async (templateName) => {
    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (!clinicDoc.exists()) {
        throw new Error('找不到診所資料');
      }

      const clinicData = clinicDoc.data();
      const doctors = clinicData.doctors || [];
      const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

      if (doctorIndex === -1) {
        throw new Error('找不到醫師資料');
      }

      // Filter out the template to delete
      const updatedTemplates = doctors[doctorIndex].serviceTemplates.filter(
        t => t.name !== templateName
      );

      // Update doctor's templates
      const updatedDoctors = [...doctors];
      updatedDoctors[doctorIndex] = {
        ...updatedDoctors[doctorIndex],
        serviceTemplates: updatedTemplates
      };

      await updateDoc(clinicRef, { doctors: updatedDoctors });
      setTemplates(updatedTemplates);
      setDeleteConfirm(null);
      toast.success('模板已刪除');
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('刪除模板失敗');
    }
  };

  // Filter templates based on search term
  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.services.some(service =>
      service.englishName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.chineseName.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearch = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="選擇醫療服務模板"
      width="max-w-3xl"
    >
      <div className="space-y-6">
        {/* Search Bar */}
        <div className="relative">
          <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="搜尋模板名稱或服務..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <div></div>
          <Link
            to="/settings"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
          >
            <FiSettings className="w-5 h-5" />
            管理模板
          </Link>
        </div>

        {/* Templates List */}
        {isLoading ? (
          <div className="flex justify-center items-center h-48">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : filteredTemplates.length > 0 ? (
          <div className="max-h-[60vh] overflow-y-auto space-y-4">
            {filteredTemplates.map((template, index) => (
              <div
                key={index}
                className="p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors relative group"
              >
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-medium text-lg">{template.name}</h3>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-500">
                      {new Date(template.createdAt).toLocaleDateString()}
                    </span>
                    {deleteConfirm === template.name ? (
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleDelete(template.name)}
                          className="text-red-500 hover:text-red-700"
                        >
                          確定刪除
                        </button>
                        <button
                          onClick={() => setDeleteConfirm(null)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          取消
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => setDeleteConfirm(template.name)}
                        className="text-gray-400 hover:text-red-500 transition-colors opacity-0 group-hover:opacity-100"
                      >
                        <FiTrash2 className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="space-y-2">
                  {template.services.map((service, serviceIndex) => (
                    <div key={serviceIndex} className="text-sm text-gray-600">
                      {service.englishName} {service.chineseName}
                      {service.credits && ` - ${service.credits} 點`}
                    </div>
                  ))}
                </div>
                <button
                  className="mt-2 px-4 py-2 text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors w-full"
                  onClick={() => {
                    onSelect(template.services);
                    closeModal();
                  }}
                >
                  套用此模板
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 text-gray-500">
            {searchTerm ? '找不到符合的模板' : '尚未建立任何模板'}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SelectMedicalServiceTemplateModal;