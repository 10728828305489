import React, { useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import { toast } from 'react-hot-toast';
import Modal from './Modal';
import { HiOutlineCheckCircle } from 'react-icons/hi';

const SaveMedicalRecordTemplateModal = ({
  isOpen,
  closeModal,
  formData,
  clinicId,
  doctorId
}) => {
  const [templateName, setTemplateName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (!templateName.trim()) {
      toast.error('請輸入模板名稱');
      return;
    }

    setIsSaving(true);
    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (!clinicDoc.exists()) {
        throw new Error('找不到診所資料');
      }

      const clinicData = clinicDoc.data();
      const doctors = clinicData.doctors || [];
      const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

      if (doctorIndex === -1) {
        throw new Error('找不到醫師資料');
      }

      // Create new template object with essential fields
      const newTemplate = {
        name: templateName,
        complains: formData.complains || '',
        pe: formData.pe || '',
        diagnosisCodes: formData.diagnosisCodes || [],
        diagnosisDetails: formData.diagnosisDetails || {},
        medicalServices: formData.medicalServices || [],
        medicalServiceDetails: formData.medicalServiceDetails || {},
        medicalServicePayments: formData.medicalServicePayments || {},
        medications: formData.medications || [],
        createdAt: new Date().toISOString()
      };

      // Update doctor's templates
      const updatedDoctors = [...doctors];
      updatedDoctors[doctorIndex] = {
        ...updatedDoctors[doctorIndex],
        medicalRecordTemplates: [
          ...(updatedDoctors[doctorIndex].medicalRecordTemplates || []),
          newTemplate
        ]
      };

      await updateDoc(clinicRef, { doctors: updatedDoctors });
      toast.success('模板已儲存');
      closeModal();
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('儲存模板失敗');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="儲存病歷模板"
      width="max-w-3xl"
    >
      <div className="space-y-6">
        {/* Template Name Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            模板名稱
          </label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="請輸入模板名稱"
            className="w-full p-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Template Preview */}
        <div className="space-y-4">
          {/* Chief Complaint Preview */}
          <div>
            <h3 className="text-sm font-medium text-gray-700">主訴</h3>
            <p className="mt-1 text-sm text-gray-600 bg-gray-50 p-2 rounded">
              {formData.complains || '無主訴記錄'}
            </p>
          </div>

          {/* Physical Examination Preview */}
          <div>
            <h3 className="text-sm font-medium text-gray-700">PE</h3>
            <p className="mt-1 text-sm text-gray-600 bg-gray-50 p-2 rounded">
              {formData.pe || '無身體檢查記錄'}
            </p>
          </div>

          {/* Diagnosis Codes Preview */}
          <div>
            <h3 className="text-sm font-medium text-gray-700">診斷碼</h3>
            <div className="mt-1 flex flex-wrap gap-2">
              {formData.diagnosisCodes?.map((code) => (
                <div key={code} className="bg-blue-50 rounded p-2 text-sm">
                  <div>{code}</div>
                  <div className="text-xs text-gray-600">
                    {formData.diagnosisDetails[code]?.chineseName}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Medical Services Preview */}
          <div>
            <h3 className="text-sm font-medium text-gray-700">醫令</h3>
            <div className="mt-1 flex flex-wrap gap-2">
              {formData.medicalServices?.map((serviceId) => (
                <div key={serviceId} className="bg-green-50 rounded p-2 text-sm">
                  <div>{serviceId}</div>
                  <div className="text-xs text-gray-600">
                    {formData.medicalServiceDetails[serviceId]?.chineseName}
                  </div>
                  <div className="text-xs text-blue-600">
                    給付方式: {formData.medicalServicePayments[serviceId] || '健保'}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Medications Preview */}
          <div>
            <h3 className="text-sm font-medium text-gray-700">藥品</h3>
            <div className="mt-1 space-y-2">
              {formData.medications?.map((med, index) => (
                <div key={index} className="bg-yellow-50 rounded p-2 text-sm">
                  <div className="font-medium">{med.englishName} {med.chineseName}</div>
                  <div className="text-xs text-gray-600">
                    {med.dosage} {med.frequency} {med.duration} - 總量: {med.total}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            取消
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center gap-2"
          >
            <HiOutlineCheckCircle className="w-5 h-5" />
            {isSaving ? '儲存中...' : '儲存模板'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveMedicalRecordTemplateModal;