import React, { useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import { toast } from 'react-hot-toast';
import Modal from './Modal';
import { FiX } from 'react-icons/fi';
import { HiOutlineCheckCircle } from 'react-icons/hi';

const SaveMedicalServiceTemplateModal = ({ 
  isOpen, 
  closeModal, 
  services, 
  clinicId, 
  doctorId 
}) => {
  const [templateName, setTemplateName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (!templateName.trim()) {
      toast.error('請輸入模板名稱');
      return;
    }

    setIsSaving(true);
    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (!clinicDoc.exists()) {
        throw new Error('找不到診所資料');
      }

      const clinicData = clinicDoc.data();
      const doctors = clinicData.doctors || [];
      const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

      if (doctorIndex === -1) {
        throw new Error('找不到醫師資料');
      }

      // Create new template object
      const newTemplate = {
        name: templateName,
        services: services.map(service => ({
          id: service.id || '',
          englishName: service.englishName || '',
          chineseName: service.chineseName || '',
          credits: service.credits || '',
        })),
        createdAt: new Date().toISOString()
      };

      // Update doctor's templates
      const updatedDoctors = [...doctors];
      updatedDoctors[doctorIndex] = {
        ...updatedDoctors[doctorIndex],
        serviceTemplates: [
          ...(updatedDoctors[doctorIndex].serviceTemplates || []),
          newTemplate
        ]
      };

      await updateDoc(clinicRef, { doctors: updatedDoctors });
      toast.success('模板已儲存');
      closeModal();
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('儲存模板失敗');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="儲存醫療服務模板"
      width="max-w-3xl"
    >
      <div className="space-y-6">
        {/* Template Name Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            模板名稱
          </label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="請輸入模板名稱"
            className="w-full p-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Services Preview */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">醫療服務清單</h3>
          <div className="max-h-96 overflow-y-auto space-y-2">
            {services.map((service, index) => (
              <div key={index} className="p-3 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium text-blue-600">{service.id}</span>
                  <span className="text-sm text-gray-400">|</span>
                  <span className="text-sm">{service.englishName} {service.chineseName}</span>
                </div>
                {service.credits && (
                  <div className="mt-1 text-sm text-gray-600">
                    點數: {service.credits}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            取消
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center gap-2"
          >
            <HiOutlineCheckCircle className="w-5 h-5" />
            {isSaving ? '儲存中...' : '儲存模板'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveMedicalServiceTemplateModal;