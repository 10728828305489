import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, query, collection, where, getDocs, setDoc, writeBatch, limit, orderBy } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import Layout from '../../Layout';
import useUserStore from '../../stores/useUserStore';
import DoctorSelector from '../../components/DoctorSelector';
import PatientList from '../../components/PatientList';
import ConsultationForm from '../../components/ConsultationForm';
import HistoryAndChat from '../../components/HistoryAndChat';
import { db } from '../../components/Firebase';
import MedicalHistoryDetailModal from '../../components/Modals/MedicalHistoryDetailModal';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import MedicalServiceUploader from '../../components/MedicalServiceUploader';
import MedicineUploader from '../../components/MedicineUploader';


function DoctorConsultation() {
  const { doctorId } = useParams();
  const formRef = useRef(null);
  const navigate = useNavigate();

  // State management
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [viewingHistoryRecord, setViewingHistoryRecord] = useState(null);
  const [unsavedRecords, setUnsavedRecords] = useState({});
  const [isShowingHistoryModal, setIsShowingHistoryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const patientListRef = useRef(null);

  const { user, targetClinicId, loading: userLoading } = useUserStore();

  // Load clinic doctors and setup initial doctor
  useEffect(() => {
    const fetchDoctors = async () => {
      if (!targetClinicId) return;

      try {
        const clinicRef = doc(db, 'OwnedClinic', targetClinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctorsList = clinicData.doctors || [];
          setDoctors(doctorsList);

          // Handle doctor selection from URL
          const urlParams = new URLSearchParams(window.location.search);
          const urlDoctorId = urlParams.get('doctorId');

          if (urlDoctorId) {
            const doctor = doctorsList.find(d => d.doctorId === urlDoctorId);
            if (doctor) {
              setSelectedDoctor(doctor);
            }
          } else if (doctorsList.length > 0) {
            setSelectedDoctor(doctorsList[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
        toast.error('無法載入診所醫師資料');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDoctors();
  }, [targetClinicId]);

  const getTodaysMedicalRecord = useCallback(async (patientId, status) => {
    if (!targetClinicId || !patientId) return null;

    const today = moment().format('YYYYMMDD');

    try {
      // 首先檢查是否有來自appointment的medicalRecordId
      const appointmentQuery = query(
        collection(db, 'Appointment'),
        where('patientId', '==', patientId),
        where('date', '==', today),
        where('ownedClinicId', '==', targetClinicId)
      );

      const appointmentSnapshot = await getDocs(appointmentQuery);
      let medicalRecordId = null;

      if (!appointmentSnapshot.empty) {
        const appointmentData = appointmentSnapshot.docs[0].data();
        medicalRecordId = appointmentData.medicalRecordId;
      }

      // 如果有medicalRecordId，獲取記錄
      if (medicalRecordId) {
        const recordDoc = await getDoc(doc(db, 'MedicalRecords', medicalRecordId));
        if (recordDoc.exists()) {
          return { id: recordDoc.id, ...recordDoc.data() };
        }
      }

      // 如果沒有找到記錄或沒有medicalRecordId，嘗試通過查詢找到
      const recordQuery = query(
        collection(db, 'MedicalRecords'),
        where('patientId', '==', patientId),
        where('clinicId', '==', targetClinicId),
        orderBy('createdAt', 'desc'),
        limit(1)
      );

      const recordSnapshot = await getDocs(recordQuery);
      if (!recordSnapshot.empty) {
        const recordData = recordSnapshot.docs[0].data();
        // 檢查記錄是否來自今天
        const recordDate = moment(recordData.createdAt).format('YYYYMMDD');
        if (recordDate === today) {
          return { id: recordSnapshot.docs[0].id, ...recordData };
        }
      }

      // 如果仍然沒有找到記錄，創建一個新的空記錄（如果狀態不是已完成）
      if (status !== '已看診') {
        return {
          id: `${patientId}_${moment().unix()}`,
          patientId: patientId,
          patientName: selectedPatient?.userName || '未知',
          complains: '',
          diagnosis: '',
          medications: [],
          imageUrls: [],
          diagnosisCodes: [],
          status: '進行中',
          clinicId: targetClinicId,
          doctorId: selectedDoctor?.doctorId,
          doctorName: selectedDoctor?.doctorName
        };
      }

      return null;
    } catch (error) {
      console.error('Error getting today\'s medical record:', error);
      return null;
    }
  }, [targetClinicId, selectedDoctor, selectedPatient]);

  useEffect(() => {
    const loadCurrentRecord = async () => {
      if (!selectedPatient || !targetClinicId) return;

      try {
        // 先檢查是否有未保存的記錄
        if (unsavedRecords[selectedPatient.id]) {
          setCurrentRecord(unsavedRecords[selectedPatient.id]);
          return;
        }

        // 嘗試獲取今天的現有記錄
        const todaysRecord = await getTodaysMedicalRecord(
          selectedPatient.patientId || selectedPatient.id,
          selectedPatient.status
        );

        if (todaysRecord) {
          setCurrentRecord(todaysRecord);
        } else {
          // 如果沒有找到記錄，創建新的空記錄結構
          const newRecordId = `${selectedPatient.patientId || selectedPatient.id}_${Date.now()}`;
          setCurrentRecord({
            id: newRecordId,
            patientId: selectedPatient.patientId || selectedPatient.id,
            patientName: selectedPatient.userName || '未知',
            complains: '',
            diagnosis: '',
            medications: [],
            imageUrls: [],
            diagnosisCodes: [],
            status: '進行中',
            clinicId: targetClinicId,
            doctorId: selectedDoctor?.doctorId,
            doctorName: selectedDoctor?.doctorName
          });
        }
      } catch (error) {
        console.error('Error loading current record:', error);
        toast.error('無法載入病歷資料');
      }
    };

    loadCurrentRecord();
  }, [selectedPatient, targetClinicId, unsavedRecords, getTodaysMedicalRecord, selectedDoctor]);

  // Handle patient selection and form state
  const handlePatientSelect = async (patient) => {
    if (!patient) return;

    // Check if form has unsaved changes
    if (formRef.current?.isFormDirty()) {
      const shouldSwitch = await formRef.current.handlePatientSwitch();
      if (!shouldSwitch) {
        return;
      }
    }

    // Save current record state to unsavedRecords if needed
    if (currentRecord && formRef.current?.isFormDirty()) {
      setUnsavedRecords(prev => ({
        ...prev,
        [selectedPatient.id]: currentRecord
      }));
    }

    // Update selected patient
    setSelectedPatient(patient);

    // Load unsaved record if exists
    if (unsavedRecords[patient.id]) {
      setCurrentRecord(unsavedRecords[patient.id]);
    } else {
      setCurrentRecord(null);
    }
  };

  // Handle viewing history record
  const handleViewHistoryRecord = (record) => {
    setViewingHistoryRecord(record);
    setIsShowingHistoryModal(true);
  };

  const handleCompleteConsultation = useCallback(() => {
    // 從checkedInPatients列表中找到下一個病患
    if (!selectedPatient) return;

    // 這個函數需要獲取待診病患列表
    if (patientListRef.current) {
      const nextPatient = patientListRef.current.getNextPatient(selectedPatient.id);
      if (nextPatient) {
        handlePatientSelect(nextPatient);
      } else {
        // 如果沒有下一個病患，清空當前選擇
        setSelectedPatient(null);
        setCurrentRecord(null);
        // 重要：確保清空所有相關狀態，包括 HistoryAndChat 需要的狀態
        setViewingHistoryRecord(null);
        if (formRef.current) {
          formRef.current.resetForm();
        }
      }
    }
  }, [selectedPatient, handlePatientSelect]);

  const handleCopyFromHistory = (record) => {
    // Create new record with all necessary fields
    const newRecord = {
      ...currentRecord, // Preserve current record ID and other metadata
      complains: record.complains || '',
      pe: record.pe || '',
      diagnosis: record.diagnosis || '',
      diagnosisCodes: record.diagnosisCodes || [],
      diagnosisDetails: record.diagnosisDetails || {},
      medicalServices: record.medicalServices || [], // 加入醫令
      medicalServiceDetails: record.medicalServiceDetails || {}, // 加入醫令詳情
      medicalServicePayments: record.medicalServicePayments || {},
      medications: record.medications || [],
      imageUrls: record.imageUrls || [],
      temperature: record.temperature || '',
      respirationRate: record.respirationRate || '',
      bloodPressure: record.bloodPressure || '',
      height: record.height || '', // 加入身高
      weight: record.weight || '', // 加入體重
      bloodOxygen: record.bloodOxygen || '', // 加入血氧
      bloodGlucose: record.bloodGlucose || '', // 加入血糖
    };

    // Update the current record state
    setCurrentRecord(newRecord);

    // Use the formRef to update the form data directly
    if (formRef.current) {
      formRef.current.updateFormData({
        complains: record.complains,
        pe: record.pe,
        diagnosis: record.diagnosis,
        diagnosisCodes: record.diagnosisCodes,
        diagnosisDetails: record.diagnosisDetails,
        medicalServices: record.medicalServices, // 加入醫令
        medicalServiceDetails: record.medicalServiceDetails, // 加入醫令詳情
        medicalServicePayments: record.medicalServicePayments,
        medications: record.medications,
        imageUrls: record.imageUrls,
        temperature: record.temperature,
        respirationRate: record.respirationRate,
        bloodPressure: record.bloodPressure,
        height: record.height, // 加入身高
        weight: record.weight, // 加入體重
        bloodOxygen: record.bloodOxygen, // 加入血氧
        bloodGlucose: record.bloodGlucose, // 加入血糖
      });
    }

    // Close the modal and show success message
    setIsShowingHistoryModal(false);
    toast.success('已複製歷史病歷內容');
  };

  // Handle record updates
  const handleRecordUpdate = (updatedRecord) => {
    setCurrentRecord(updatedRecord);
    setUnsavedRecords(prev => ({
      ...prev,
      [selectedPatient.id]: updatedRecord
    }));
  };

  // Loading state
  if (isLoading || userLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main"></div>
        </div>
      </Layout>
    );
  }

  // Show warning if no clinic selected
  if (!targetClinicId) {
    return (
      <Layout>
        <div className="text-center p-4">
          <p className="text-red-500">請先選擇診所</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold mb-6">醫師門診</h1>

        {/* Main content grid */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
          {/* Left sidebar - Doctor selection & Patient list */}
          {/* Left sidebar - Doctor selection & Patient list */}
          <div className="lg:col-span-2 bg-white rounded-xl border border-gray-200 p-4">
            {/* QR Code for mobile access */}
            <div className="mb-4 p-2 border border-gray-200 rounded-lg bg-white flex flex-col items-center">
              <p className="text-sm font-medium text-gray-700 mb-2">手機掃描開啟此頁面</p>
              <QRCodeSVG
                value={`${window.location.origin}/doctor-consultation?doctorId=${selectedDoctor?.doctorId || ''}`}
                size={120}
              />
            </div>

            <DoctorSelector
              doctors={doctors}
              selectedDoctor={selectedDoctor}
              onDoctorSelect={setSelectedDoctor}
            />
            <PatientList
              ref={patientListRef}
              selectedDoctor={selectedDoctor}
              targetClinicId={targetClinicId}
              onPatientSelect={handlePatientSelect}
              selectedPatientId={selectedPatient?.id}
            />
          </div>

          {/* Main consultation form */}
          <div className="lg:col-span-7 bg-white rounded-xl border border-gray-200 p-4">
            <ConsultationForm
              ref={formRef}
              selectedPatient={selectedPatient}
              selectedDoctor={selectedDoctor}
              currentRecord={currentRecord}
              onRecordUpdate={handleRecordUpdate}
              targetClinicId={targetClinicId}
              onCompleteConsultation={handleCompleteConsultation}
            />
          </div>

          {/* Right sidebar - History & Chat */}
          <div className="lg:col-span-3 bg-white rounded-xl border border-gray-200 p-4">
            <HistoryAndChat
              selectedPatient={selectedPatient}
              onViewRecord={handleViewHistoryRecord}
              onCopyRecord={handleCopyFromHistory}
            />
          </div>
        </div>

        {/* Medical History Detail Modal */}
        {isShowingHistoryModal && viewingHistoryRecord && (
          <MedicalHistoryDetailModal
            isOpen={isShowingHistoryModal}
            onClose={() => setIsShowingHistoryModal(false)}
            record={viewingHistoryRecord}
            onCopy={handleCopyFromHistory}
          />
        )}
      </div>
      {/* <ICDCodesUploader/>
      <MedicalServiceUploader/> 
        <MedicineUploader/> */}
    </Layout>
  );
}

export default DoctorConsultation;