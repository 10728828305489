import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { db } from './Firebase';
import { getTaiwanTimeString } from './TaiwanTimeStamp';
import MedicineSearch from './MedicineSearch';
import Uploader from './Uploader';
import ICDCodeSelector from './ICDCodeSelector';
import MedicalServiceSelector from './MedicalServiceSelector';
import SaveMedicalRecordTemplateModal from './Modals/SaveMedicalRecordTemplateModal';
import SelectMedicalRecordTemplateModal from './Modals/SelectMedicalRecordTemplateModal';
import MedicalServicesSummary from './MedicalServicesSummary';
import ConsultationSummaryModal from './Modals/ConsultationSummaryModal';
import MedicineSummary from './MedicineSummary';

const ConsultationForm = forwardRef(({
  selectedPatient,
  selectedDoctor,
  currentRecord,
  onRecordUpdate,
  targetClinicId,
  onCompleteConsultation
}, ref) => {
  // Initialize form state with empty values
  const [formData, setFormData] = useState({
    complains: '',
    pe: '', // Physical Examination
    diagnosis: '',
    medications: [],
    imageUrls: [],
    diagnosisCodes: [],
    diagnosisDetails: {},
    medicalServices: [], // Add this
    medicalServiceDetails: {}, // Add this
    medicalServicePayments: {},
    temperature: '',
    respirationRate: '',
    bloodPressure: '',
    height: '',
    weight: '',
    bloodOxygen: '',
    bloodGlucose: '',
    selfPaidTotal: 0,
    adjustedTotal: 0,
    paymentTypeTotals: {},
    medicineInsuranceTotal: 0,
    medicineSelfPaidTotal: 0,
    medicineTotalCount: 0,
    refillCount: '0',
  });

  const [activeTab, setActiveTab] = useState('complains'); // 'complains' or 'pe'
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const shouldShowSaveButton = currentRecord?.status !== '完成' && currentRecord?.status !== '已看診';
  const [expandedNotes, setExpandedNotes] = useState({});
  const [serviceNotes, setServiceNotes] = useState({});
  const [showSummaryModal, setShowSummaryModal] = useState(false);

  // Reset form when patient changes
  useEffect(() => {
    if (!selectedPatient || !currentRecord) return;

    const notes = {};
    if (currentRecord.medicalServiceDetails) {
      Object.keys(currentRecord.medicalServiceDetails).forEach(serviceId => {
        if (currentRecord.medicalServiceDetails[serviceId]?.notes) {
          notes[serviceId] = currentRecord.medicalServiceDetails[serviceId].notes;
        }
      });
    }
    setServiceNotes(notes);

    setFormData(prevData => ({
      ...prevData,
      complains: currentRecord.complains || '',
      pe: currentRecord.pe || '',
      diagnosis: currentRecord.diagnosis || '',
      medications: currentRecord.medications || [],
      imageUrls: currentRecord.imageUrls || [],
      diagnosisCodes: currentRecord.diagnosisCodes || [],
      diagnosisDetails: currentRecord.diagnosisDetails || {},
      medicalServices: currentRecord.medicalServices || [],
      medicalServiceDetails: currentRecord.medicalServiceDetails || {},
      medicalServicePayments: currentRecord.medicalServicePayments || {},
      temperature: currentRecord.temperature || '',
      respirationRate: currentRecord.respirationRate || '',
      bloodPressure: currentRecord.bloodPressure || '',
      height: currentRecord.height || '',
      weight: currentRecord.weight || '',
      bloodOxygen: currentRecord.bloodOxygen || '',
      bloodGlucose: currentRecord.bloodGlucose || ''
    }));
  }, [selectedPatient]);

  const resetForm = () => {
    setFormData({
      complains: '',
      pe: '',
      diagnosis: '',
      medications: [],
      imageUrls: [],
      diagnosisCodes: [],
      diagnosisDetails: {},
      medicalServices: [],
      medicalServiceDetails: {},
      medicalServicePayments: {},
      temperature: '',
      respirationRate: '',
      bloodPressure: '',
      height: '',
      weight: '',
      bloodOxygen: '',
      bloodGlucose: '',
      selfPaidTotal: 0,
      adjustedTotal: 0,
      paymentTypeTotals: {},
      medicineInsuranceTotal: 0,
      medicineSelfPaidTotal: 0,
      medicineTotalCount: 0,
    });
    setServiceNotes({});
    setIsFormDirty(false);
  };

  const handleFormChange = (field, value) => {
    const newFormData = {
      ...formData,
      [field]: value
    };
    setFormData(newFormData);
    setIsFormDirty(true);

    // Only notify parent if there's a current record
    if (currentRecord) {
      onRecordUpdate?.({
        ...currentRecord,
        [field]: value,
        lastUpdated: getTaiwanTimeString()
      });
    }
  };

  const initializeNewService = (service) => {
    // Get current time and format it to HH:mm
    const currentTime = moment().format('HH:mm');
    const endTime = moment().add(10, 'minutes').format('HH:mm');

    console.log('Initializing service with times:', { currentTime, endTime });

    return {
      englishName: service.englishName || '',
      chineseName: service.chineseName || '',
      credits: service.credits || '',
      notes: service.notes || '',
      startTime: currentTime,
      endTime: endTime
    };
  };

  const handleTimeChange = async (serviceId, timeField, timeValue) => {
    try {

      // Create deep copy of the current service details to avoid reference issues
      const updatedDetails = JSON.parse(JSON.stringify(formData.medicalServiceDetails || {}));

      // Ensure the service exists in updatedDetails
      if (!updatedDetails[serviceId]) {
        updatedDetails[serviceId] = {};
      }

      // Update the specific time field with the new value
      updatedDetails[serviceId][timeField] = timeValue;

      // Update local state with the new details
      setFormData(prev => ({
        ...prev,
        medicalServiceDetails: updatedDetails
      }));

      // Mark form as dirty to prompt save
      setIsFormDirty(true);

      // Update Firestore if we have a valid record
      if (selectedPatient?.medicalRecordId && currentRecord) {
        const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);

        // Use merge to update only the specific field
        await setDoc(recordRef, {
          medicalServiceDetails: updatedDetails,
          lastUpdated: getTaiwanTimeString()
        }, { merge: true });

        // Notify parent of the update
        onRecordUpdate?.({
          ...currentRecord,
          medicalServiceDetails: updatedDetails,
          lastUpdated: getTaiwanTimeString()
        });

      }
    } catch (error) {
      console.error(`Error updating ${timeField}:`, error);
      toast.error(`更新${timeField === 'startTime' ? '開始' : '結束'}時間失敗`);
    }
  };

  // In ConsultationForm.js, modify the handleSummaryCalculated function:
  const handleSummaryCalculated = useCallback((calculatedSummary) => {
    // Create a proper paymentTypeTotals with adjustedTotal for 健保
    const properPaymentTypeTotals = {
      '健保': calculatedSummary.adjustedTotal || 0,  // Use adjusted total for 健保
      '自費': calculatedSummary.paymentTypeTotals['自費'] || 0
    };

    // Only update if values have actually changed
    const hasChanged =
      formData.selfPaidTotal !== calculatedSummary.paymentTypeTotals['自費'] ||
      formData.adjustedTotal !== calculatedSummary.adjustedTotal ||
      JSON.stringify(formData.paymentTypeTotals) !== JSON.stringify(properPaymentTypeTotals);

    if (hasChanged) {
      // Update the form data with the calculated summary values
      setFormData(prevData => ({
        ...prevData,
        selfPaidTotal: calculatedSummary.paymentTypeTotals['自費'] || 0,
        adjustedTotal: calculatedSummary.adjustedTotal || 0,
        paymentTypeTotals: properPaymentTypeTotals
      }));

      // Only mark as dirty if values actually changed
      setIsFormDirty(true);

      // Update the current record if available
      if (currentRecord) {
        onRecordUpdate?.({
          ...currentRecord,
          selfPaidTotal: calculatedSummary.paymentTypeTotals['自費'] || 0,
          adjustedTotal: calculatedSummary.adjustedTotal || 0,
          paymentTypeTotals: properPaymentTypeTotals
        });
      }
    }
  }, [formData.selfPaidTotal, formData.adjustedTotal, formData.paymentTypeTotals, currentRecord, onRecordUpdate]);

  const saveCurrentRecord = async () => {
    if (!selectedPatient?.medicalRecordId) {
      toast.error('無法保存：找不到病歷ID');
      return false;
    }

    try {
      // Clean and validate medications data
      const cleanedMedications = formData.medications.map(med => ({
        id: med.id || '',
        englishName: med.englishName || '',
        chineseName: med.chineseName || '',
        dosage: med.dosage || '',
        frequency: med.frequency || '',
        duration: med.duration || '',
        paymentType: med.paymentType || '健保',
        total: med.total || '0',
        spec: med.spec || null,
        price: med.price || '',
        customDosage: !!med.customDosage,
        customFrequency: !!med.customFrequency,
        customDuration: !!med.customDuration,
        prescriptionType: med.prescriptionType || 'general'
      }));


      const cleanedServiceDetails = { ...formData.medicalServiceDetails };
      for (const serviceId of formData.medicalServices) {
        if (!cleanedServiceDetails[serviceId]) {
          cleanedServiceDetails[serviceId] = {};
        }


        if (serviceNotes[serviceId] && !cleanedServiceDetails[serviceId].notes) {
          cleanedServiceDetails[serviceId] = {
            ...cleanedServiceDetails[serviceId],
            notes: serviceNotes[serviceId]
          };
        }
      }


      const cleanedPayments = { ...formData.medicalServicePayments };
      for (const serviceId of formData.medicalServices) {
        if (!cleanedPayments[serviceId]) {
          cleanedPayments[serviceId] = '健保'; 
        }
      }

      const recordData = {
        patientId: selectedPatient.patientId,
        patientName: selectedPatient.userName,
        patientTwId: selectedPatient.userTWId,
        clinicId: targetClinicId,
        doctorId: selectedDoctor.doctorId,
        doctorName: selectedDoctor.doctorName,
        complains: formData.complains || '',
        pe: formData.pe || '',
        diagnosis: formData.diagnosis || '',
        medications: cleanedMedications,
        imageUrls: formData.imageUrls || [],
        diagnosisCodes: formData.diagnosisCodes || [],
        diagnosisDetails: formData.diagnosisDetails || {},
        medicalServices: formData.medicalServices || [],
        medicalServiceDetails: cleanedServiceDetails,
        medicalServicePayments: cleanedPayments,
        temperature: formData.temperature || '',
        respirationRate: formData.respirationRate || '',
        bloodPressure: formData.bloodPressure || '',
        height: formData.height || '',
        weight: formData.weight || '',
        bloodOxygen: formData.bloodOxygen || '',
        bloodGlucose: formData.bloodGlucose || '',
        refillCount: formData.refillCount || '0',

        // Add the summary values to be stored in Firebase
        selfPaidTotal: formData.selfPaidTotal || 0,
        adjustedTotal: formData.adjustedTotal || 0,
        paymentTypeTotals: formData.paymentTypeTotals || {},

        status: currentRecord?.status || '進行中',
        lastUpdated: getTaiwanTimeString(),
        createdAt: currentRecord?.createdAt || getTaiwanTimeString()
      };

      const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
      await setDoc(recordRef, recordData);

      setIsFormDirty(false);
      onRecordUpdate?.(recordData);

      toast.success('病歷已更新');
      return true;

    } catch (error) {
      console.error('Error saving record:', error);
      toast.error('儲存失敗');
      return false;
    }
  };

  const completeConsultation = async () => {
    if (!selectedPatient?.medicalRecordId) {
      toast.error('無法完成：找不到病歷ID');
      return;
    }
  
    try {
      // First save current record to ensure all data is up to date
      const saved = await saveCurrentRecord();
      if (!saved) return;
  
      // Update the current record to include refillCount before showing the modal
      if (currentRecord) {
        const updatedRecord = {
          ...currentRecord,
          refillCount: formData.refillCount
        };
        
        // Update the record in Firestore to make sure refillCount is saved
        const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
        await setDoc(recordRef, { refillCount: formData.refillCount }, { merge: true });
        
        // Notify parent of the update
        onRecordUpdate?.(updatedRecord);
        
        // Show summary modal with the updated record
        setShowSummaryModal(true);
      } else {
        setShowSummaryModal(true);
      }
    } catch (error) {
      console.error('Error preparing consultation completion:', error);
      toast.error('準備看診完成資料失敗');
    }
  };

  const finalizeConsultation = async () => {
    try {
      // Update medical record status
      const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
      await setDoc(recordRef, {
        status: '完成',
        completedAt: getTaiwanTimeString()
      }, { merge: true });

      // Update appointment status if exists
      if (selectedPatient.id) {
        const appointmentRef = doc(db, 'Appointment', selectedPatient.id);
        await setDoc(appointmentRef, {
          status: '已看診',
          lastUpdated: getTaiwanTimeString()
        }, { merge: true });
      }

      toast.success('已完成看診');
      resetForm();

      // Notify parent to move to next patient if needed
      if (typeof onCompleteConsultation === 'function') {
        onCompleteConsultation();
      }

      return true;
    } catch (error) {
      console.error('Error finalizing consultation:', error);
      toast.error('完成看診失敗');
      throw error;
    }
  };

  const handleMedicineSummaryCalculated = useCallback((calculatedSummary) => {
    const hasChanged =
      formData.medicineSelfPaidTotal !== calculatedSummary.selfPaidTotal ||
      formData.medicineInsuranceTotal !== calculatedSummary.insuranceTotal;

    if (hasChanged) {
      // Update the form data with the calculated summary values
      setFormData(prevData => ({
        ...prevData,
        medicineSelfPaidTotal: calculatedSummary.selfPaidTotal || 0,
        medicineInsuranceTotal: calculatedSummary.insuranceTotal || 0,
        medicineTotalCount: calculatedSummary.totalCount || 0
      }));

      // Mark as dirty if values actually changed
      setIsFormDirty(true);

      // Update the current record if available
      if (currentRecord) {
        onRecordUpdate?.({
          ...currentRecord,
          medicineSelfPaidTotal: calculatedSummary.selfPaidTotal || 0,
          medicineInsuranceTotal: calculatedSummary.insuranceTotal || 0,
          medicineTotalCount: calculatedSummary.totalCount || 0
        });
      }
    }
  }, [formData.medicineSelfPaidTotal, formData.medicineInsuranceTotal, currentRecord, onRecordUpdate]);

  // Handler for patient switching
  const handlePatientSwitch = async () => {
    if (isFormDirty) {
      const confirmed = window.confirm('您有未儲存的變更，是否要儲存？');
      if (confirmed) {
        return await saveCurrentRecord();
      }
    }
    resetForm();
    return true;
  };

  // Handle image operations
  const handleImageUpload = async (imageUrl) => {
    const newImages = [...formData.imageUrls, imageUrl];
    handleFormChange('imageUrls', newImages);
  };

  const handleImageDelete = (urlToDelete) => {
    const newImages = formData.imageUrls.filter(url => url !== urlToDelete);
    handleFormChange('imageUrls', newImages);
  };

  // Expose methods to parent
  useImperativeHandle(ref, () => ({
    isFormDirty: () => isFormDirty,
    saveCurrentRecord,
    completeConsultation,
    handlePatientSwitch,
    resetForm,
    updateFormData: (data) => {
      setFormData({
        complains: data.complains || '',
        pe: data.pe || '',
        diagnosis: data.diagnosis || '',
        medications: data.medications || [],
        imageUrls: data.imageUrls || [],
        diagnosisCodes: data.diagnosisCodes || [],
        diagnosisDetails: data.diagnosisDetails || {},
        medicalServices: data.medicalServices || [],
        medicalServiceDetails: data.medicalServiceDetails || {},
        medicalServicePayments: data.medicalServicePayments || {},
        temperature: data.temperature || '',
        respirationRate: data.respirationRate || '',
        bloodPressure: data.bloodPressure || '',
        height: data.height || '',
        weight: data.weight || '',
        bloodOxygen: data.bloodOxygen || '',
        bloodGlucose: data.bloodGlucose || '',
        // Also preserve summary values if available
        selfPaidTotal: data.selfPaidTotal || 0,
        adjustedTotal: data.adjustedTotal || 0,
        paymentTypeTotals: data.paymentTypeTotals || {}
      });

      // Update service notes
      const notes = {};
      if (data.medicalServiceDetails) {
        Object.keys(data.medicalServiceDetails).forEach(serviceId => {
          if (data.medicalServiceDetails[serviceId]?.notes) {
            notes[serviceId] = data.medicalServiceDetails[serviceId].notes;
          }
        });
      }
      setServiceNotes(notes);

      setIsFormDirty(true);

      // Also notify parent of the update with complete data
      if (currentRecord) {
        onRecordUpdate?.({
          ...currentRecord,
          ...data,
          lastUpdated: getTaiwanTimeString()
        });
      }
    }
  }));

  const handlePaymentTypeChange = async (serviceId, newPaymentType) => {
    try {
      // Create a new object (not just reference) for the payments
      const updatedPayments = {
        ...(formData.medicalServicePayments || {}),
        [serviceId]: newPaymentType
      };

      // Create a new object for the service details
      let updatedDetails = JSON.parse(JSON.stringify(formData.medicalServiceDetails || {}));
      if (!updatedDetails[serviceId]) {
        updatedDetails[serviceId] = {};
      }

      // Update local state with new objects to ensure React detects the change
      setFormData(prevState => ({
        ...prevState,
        medicalServicePayments: updatedPayments,
        medicalServiceDetails: updatedDetails
      }));

      setIsFormDirty(true);

      // Update Firestore
      if (selectedPatient?.medicalRecordId && currentRecord) {
        const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
        await setDoc(recordRef, {
          medicalServicePayments: updatedPayments,
          medicalServiceDetails: updatedDetails,
          lastUpdated: getTaiwanTimeString()
        }, { merge: true });

        // Update parent component
        onRecordUpdate?.({
          ...currentRecord,
          medicalServicePayments: updatedPayments,
          medicalServiceDetails: updatedDetails,
          lastUpdated: getTaiwanTimeString()
        });
      }
    } catch (error) {
      console.error('Error updating payment type:', error);
      toast.error('更新給付方式失敗');
    }
  };

  const handleDropdownOpen = (componentName) => {
    setActiveDropdown(componentName);
  };

  const handleDropdownClose = () => {
    setActiveDropdown(null);
  };

  if (!selectedPatient) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500 text-lg">請選擇病患以開始看診</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Patient Info Header */}
      <div className="grid grid-cols-4 gap-4 bg-gray-50 p-4 rounded-lg">
        <div>
          <p className="text-sm text-gray-500">姓名</p>
          <p className="font-medium">{selectedPatient.userName || '未知'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">身分證</p>
          <p className="font-medium">{selectedPatient.userTWId || '未知'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">生日</p>
          <p className="font-medium">{selectedPatient.birthday || '未知'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">年齡</p>
          <p className="font-medium">
            {selectedPatient.birthday
              ? moment().diff(moment(selectedPatient.birthday), 'years')
              : '未知'}
          </p>
        </div>
      </div>

      {/* Main Form Content */}
      <div className="space-y-6">
        {/* Tab Interface */}
        <div className="flex items-center border-b border-gray-200">
          {/* Left side: Tab buttons */}
          <div className="flex space-x-4">
            <button
              className={`px-4 py-2 font-medium ${activeTab === 'complains'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-gray-700'
                }`}
              onClick={() => setActiveTab('complains')}
            >
              CC
            </button>
            <button
              className={`px-4 py-2 font-medium ${activeTab === 'pe'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-gray-700'
                }`}
              onClick={() => setActiveTab('pe')}
            >
              PE
            </button>
          </div>

          {/* Right side: Template buttons */}
          <div className="flex gap-2 ml-auto">
            <button
              type="button"
              onClick={() => setShowSelectTemplateModal(true)}
              className="px-4 py-2 text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100"
            >
              載入病歷模板
            </button>
            <button
              type="button"
              onClick={() => setShowSaveTemplateModal(true)}
              className="px-4 py-2 text-green-600 bg-green-50 rounded-lg hover:bg-green-100 mr-2"
            >
              儲存為病歷模板
            </button>
          </div>
        </div>

        {/* Chief Complaint or PE */}
        {activeTab === 'complains' ? (
          <div>
            <textarea
              value={formData.complains}
              onChange={(e) => handleFormChange('complains', e.target.value)}
              rows={2}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="病患主訴..."
            />
          </div>
        ) : (
          <div>
            <textarea
              value={formData.pe}
              onChange={(e) => handleFormChange('pe', e.target.value)}
              rows={2}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="身體檢查結果..."
            />
          </div>
        )}

        {/* Vital Signs */}
        <div className="grid grid-cols-6 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              體溫 (°C)
            </label>
            <input
              type="text"
              value={formData.temperature}
              onChange={(e) => handleFormChange('temperature', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="36.5"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              呼吸 (次/分)
            </label>
            <input
              type="text"
              value={formData.respirationRate}
              onChange={(e) => handleFormChange('respirationRate', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="16"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              血壓 (mmHg)
            </label>
            <input
              type="text"
              value={formData.bloodPressure}
              onChange={(e) => handleFormChange('bloodPressure', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="120/80"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              身高 (cm)
            </label>
            <input
              type="text"
              value={formData.height}
              onChange={(e) => handleFormChange('height', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="170"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              體重 (kg)
            </label>
            <input
              type="text"
              value={formData.weight}
              onChange={(e) => handleFormChange('weight', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="70"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              血糖 (mg/dL)
            </label>
            <input
              type="text"
              value={formData.bloodGlucose}
              onChange={(e) => handleFormChange('bloodGlucose', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="100"
            />
          </div>
        </div>

        {/* Diagnosis Codes */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            診斷碼
          </label>
          <ICDCodeSelector
            onSelect={(selectedCode) => {
              if (!formData.diagnosisCodes.includes(selectedCode.id)) {
                // Update both codes and details atomically
                const newFormData = {
                  ...formData,
                  diagnosisCodes: [...formData.diagnosisCodes, selectedCode.id],
                  diagnosisDetails: {
                    ...formData.diagnosisDetails,
                    [selectedCode.id]: {
                      englishName: selectedCode.englishName,
                      chineseName: selectedCode.chineseName
                    }
                  }
                };
                setFormData(newFormData);
                setIsFormDirty(true);

                if (currentRecord) {
                  onRecordUpdate?.({
                    ...currentRecord,
                    diagnosisCodes: newFormData.diagnosisCodes,
                    diagnosisDetails: newFormData.diagnosisDetails,
                    lastUpdated: getTaiwanTimeString()
                  });
                }
              }
            }}
            clinicId={targetClinicId}
            doctorId={selectedDoctor.doctorId}
            isActive={activeDropdown === 'icdCode'}
            onDropdownOpen={() => handleDropdownOpen('icdCode')}
            onDropdownClose={handleDropdownClose}
            zIndex={activeDropdown === 'icdCode' ? 30 : 10}
          />

          {/* Display selected codes */}
          <div className="mt-2 flex flex-wrap gap-2">
            {formData.diagnosisCodes.map((code) => (
              <div
                key={code}
                className="group inline-flex items-center bg-blue-100 hover:bg-gray-200 
                         rounded-lg px-3 py-1.5 text-sm transition-colors"
              >
                <div className="flex flex-col">
                  <span className="font-medium">{code}</span>
                  {formData.diagnosisDetails[code]?.chineseName && (
                    <span className="text-xs text-gray-600">
                      {formData.diagnosisDetails[code].englishName} |
                      {formData.diagnosisDetails[code].chineseName}
                    </span>
                  )}
                </div>
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      // 1. 過濾掉要刪除的代碼
                      const newCodes = formData.diagnosisCodes.filter(c => c !== code);

                      // 2. 創建全新的 details 對象，只包含剩餘的代碼
                      const newDetails = {};
                      newCodes.forEach(remainingCode => {
                        if (formData.diagnosisDetails[remainingCode]) {
                          newDetails[remainingCode] = formData.diagnosisDetails[remainingCode];
                        }
                      });

                      // 3. 準備更新的數據
                      const updatedData = {
                        diagnosisCodes: newCodes,
                        diagnosisDetails: newDetails,
                        lastUpdated: getTaiwanTimeString()
                      };

                      // 4. 首先更新 Firestore
                      if (selectedPatient?.medicalRecordId) {
                        const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
                        await setDoc(recordRef, {
                          ...updatedData
                        }, { merge: true });
                      }

                      // 5. 更新本地狀態
                      setFormData(prev => ({
                        ...prev,
                        ...updatedData
                      }));
                      setIsFormDirty(true);

                      // 6. 通知父組件
                      if (currentRecord) {
                        onRecordUpdate?.({
                          ...currentRecord,
                          ...updatedData
                        });
                      }

                      toast.success('診斷碼已更新');
                    } catch (error) {
                      console.error('Error updating diagnosis codes:', error);
                      toast.error('更新診斷碼失敗');
                    }
                  }}
                  className="ml-2 text-gray-400 hover:text-red-500 transition-colors"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Medical Services Section */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            醫令
          </label>
          <MedicalServiceSelector
            onSelect={(selectedServices, isTemplate) => {
              // Ensure we have an array of services
              const servicesToAdd = Array.isArray(selectedServices) ? selectedServices : [selectedServices];

              // Filter to only new services
              const newServiceIds = servicesToAdd
                .filter(service => !formData.medicalServices.includes(service.id))
                .map(service => service.id);

              if (newServiceIds.length === 0) return;

              // Build new service details and payments using our new helper
              const newServiceDetails = {};
              const newServicePayments = {};

              servicesToAdd.forEach(service => {
                if (newServiceIds.includes(service.id)) {
                  newServiceDetails[service.id] = initializeNewService(service);
                  newServicePayments[service.id] = service.paymentType || '健保'; // Default payment type
                }
              });

              // Update form data with all new services
              const newFormData = {
                ...formData,
                medicalServices: [...formData.medicalServices, ...newServiceIds],
                medicalServiceDetails: {
                  ...formData.medicalServiceDetails,
                  ...newServiceDetails
                },
                medicalServicePayments: {
                  ...formData.medicalServicePayments,
                  ...newServicePayments
                }
              };

              setFormData(newFormData);
              setIsFormDirty(true);

              if (currentRecord) {
                onRecordUpdate?.({
                  ...currentRecord,
                  medicalServices: newFormData.medicalServices,
                  medicalServiceDetails: newFormData.medicalServiceDetails,
                  medicalServicePayments: newFormData.medicalServicePayments,
                  lastUpdated: getTaiwanTimeString()
                });
              }
            }
            }
            clinicId={targetClinicId}
            doctorId={selectedDoctor.doctorId}
            isActive={activeDropdown === 'medicalService'}
            onDropdownOpen={() => handleDropdownOpen('medicalService')}
            onDropdownClose={handleDropdownClose}
            zIndex={activeDropdown === 'medicalService' ? 30 : 10}
          />

          {/* Display selected medical services */}
          {formData.medicalServices && formData.medicalServices.length > 0 ? (
            <div className="mt-2 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      診療項目
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      健保支付點數
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      名稱
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      開始時間
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      結束時間
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      付費方式
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      備註
                    </th>
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      操作
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {formData.medicalServices.map((serviceId) => {
                    const serviceDetail = formData.medicalServiceDetails?.[serviceId] || {};
                    const hasExpandedNotes = expandedNotes[serviceId] || false;

                    return (
                      <tr key={serviceId} className="hover:bg-gray-50">
                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-blue-600">
                          {serviceId}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                          {serviceDetail.credits || '-'}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-900">
                          <div className="flex flex-col">
                            <span>{serviceDetail.englishName || ''}</span>
                            <span className="text-xs text-gray-600">{serviceDetail.chineseName || ''}</span>
                          </div>
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">
                          <input
                            type="time"
                            value={serviceDetail.startTime || moment().format('HH:mm')}
                            onChange={(e) => {
                              handleTimeChange(serviceId, 'startTime', e.target.value);
                            }}
                            className="text-sm bg-white border border-gray-300 rounded px-2 py-1"
                          />
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">
                          <input
                            type="time"
                            value={serviceDetail.endTime || moment().add(5, 'minutes').format('HH:mm')}
                            onChange={(e) => {
                              handleTimeChange(serviceId, 'endTime', e.target.value);
                            }}
                            className="text-sm bg-white border border-gray-300 rounded px-2 py-1"
                          />
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">
                          <select
                            value={formData.medicalServicePayments?.[serviceId] || '健保'}
                            onChange={(e) => handlePaymentTypeChange(serviceId, e.target.value)}
                            className="text-sm bg-white border border-gray-300 rounded px-2 py-1"
                          >
                            <option value="健保">健保</option>
                            <option value="自費">自費</option>
                            <option value="急診">急診</option>
                          </select>
                        </td>
                        <td className="px-4 py-2 text-sm">
                          <div className="relative">
                            {serviceDetail.notes ? (
                              <>
                                <div className={`${expandedNotes[serviceId] ? '' : 'line-clamp-1'} pr-6`}>
                                  {serviceDetail.notes}
                                </div>
                                <button
                                  onClick={() => setExpandedNotes(prev => ({
                                    ...prev,
                                    [serviceId]: !prev[serviceId]
                                  }))}
                                  className="absolute right-0 top-0 text-blue-500 hover:text-blue-700"
                                  title={expandedNotes[serviceId] ? "收起" : "展開"}
                                >
                                  {expandedNotes[serviceId] ? "↑" : "↓"}
                                </button>
                              </>
                            ) : (
                              <span className="text-gray-400 italic">無備註</span>
                            )}
                          </div>
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-right">
                          <button
                            type="button"
                            onClick={async () => {
                              try {
                                if (!Array.isArray(formData.medicalServices)) return;

                                // Create deep copies of arrays and objects
                                const newServices = [...formData.medicalServices.filter(s => s !== serviceId)];

                                // Create new objects for the details and payments
                                const newDetails = {};
                                const newPayments = {};

                                // Only copy over the remaining services
                                newServices.forEach(remainingService => {
                                  if (formData.medicalServiceDetails?.[remainingService]) {
                                    newDetails[remainingService] = JSON.parse(
                                      JSON.stringify(formData.medicalServiceDetails[remainingService])
                                    );
                                  }
                                  if (formData.medicalServicePayments?.[remainingService]) {
                                    newPayments[remainingService] = formData.medicalServicePayments[remainingService];
                                  }
                                });

                                // Create a completely new formData object
                                const updatedFormData = {
                                  ...formData,
                                  medicalServices: newServices,
                                  medicalServiceDetails: newDetails,
                                  medicalServicePayments: newPayments,
                                };

                                // First update local state to ensure UI responds
                                setFormData(updatedFormData);
                                setIsFormDirty(true);

                                // Also update service notes
                                const newServiceNotes = { ...serviceNotes };
                                delete newServiceNotes[serviceId];
                                setServiceNotes(newServiceNotes);

                                // Then update Firestore
                                if (selectedPatient?.medicalRecordId) {
                                  const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
                                  await setDoc(recordRef, {
                                    medicalServices: newServices,
                                    medicalServiceDetails: newDetails,
                                    medicalServicePayments: newPayments,
                                    lastUpdated: getTaiwanTimeString()
                                  }, { merge: true });
                                }

                                // Notify parent component
                                if (currentRecord) {
                                  onRecordUpdate?.({
                                    ...currentRecord,
                                    medicalServices: newServices,
                                    medicalServiceDetails: newDetails,
                                    medicalServicePayments: newPayments,
                                    lastUpdated: getTaiwanTimeString()
                                  });
                                }

                                toast.success('醫療服務項目已更新');
                              } catch (error) {
                                console.error('Error updating medical services:', error);
                                toast.error('更新醫療服務項目失敗');
                              }
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>


            </div>
          ) : (
            <p className="text-gray-500 text-sm italic mt-2"></p>
          )

          }

        </div >

        {/* Medications */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            藥令
          </label>
          <MedicineSearch
            medications={formData.medications}
            onChange={(newMedications) => handleFormChange('medications', newMedications)}
            clinicId={targetClinicId}
            doctorId={selectedDoctor?.doctorId}
            isActive={activeDropdown === 'medicine'}
            onDropdownOpen={() => handleDropdownOpen('medicine')}
            onDropdownClose={handleDropdownClose}
            zIndex={activeDropdown === 'medicine' ? 30 : 10}
            refillCount={formData.refillCount} // Add this
            onRefillCountChange={(value) => handleFormChange('refillCount', value)}
          />
        </div>

        {/* Image Upload */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            上傳圖片
          </label>
          <Uploader
            images={formData.imageUrls}
            onUpload={handleImageUpload}
            onDelete={handleImageDelete}
          />
        </div>
        <>
          <MedicalServicesSummary
            medicalServices={formData.medicalServices}
            medicalServiceDetails={formData.medicalServiceDetails}
            medicalServicePayments={formData.medicalServicePayments}
            onSummaryCalculated={handleSummaryCalculated}
          />

          <MedicineSummary
            medications={formData.medications}
            onSummaryCalculated={handleMedicineSummaryCalculated}
          />
        </>
      </div>

      {/* Form Actions */}
      <div className="sticky bottom-0 bg-white pt-4 pb-2 border-t border-gray-200 mt-6">
        <div className="flex justify-end space-x-4">
          {shouldShowSaveButton && (
            <button
              type="button"
              onClick={saveCurrentRecord}
              className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 shadow-lg transition-all duration-200 hover:shadow-xl"
            >
              暫存病歷
            </button>
          )}
          <button
            type="button"
            onClick={completeConsultation}
            className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 shadow-lg transition-all duration-200 hover:shadow-xl"
          >
            完成看診
          </button>
        </div>
      </div>

      {/* Template Modals */}
      {
        showSaveTemplateModal && (
          <SaveMedicalRecordTemplateModal
            isOpen={showSaveTemplateModal}
            closeModal={() => setShowSaveTemplateModal(false)}
            formData={formData}
            clinicId={targetClinicId}
            doctorId={selectedDoctor.doctorId}
          />
        )
      }

      {showSummaryModal && (
        <ConsultationSummaryModal
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          record={currentRecord}
          patient={selectedPatient}
          clinicName={"健康診所"} // Pass your clinic name here
          onPrint={finalizeConsultation}
        />
      )}

      {
        showSelectTemplateModal && (
          <SelectMedicalRecordTemplateModal
            isOpen={showSelectTemplateModal}
            closeModal={() => setShowSelectTemplateModal(false)}
            onSelect={(templateData) => {
              setFormData(prev => ({
                ...prev,
                ...templateData
              }));
              setIsFormDirty(true);
              if (currentRecord) {
                onRecordUpdate?.({
                  ...currentRecord,
                  ...templateData,
                  lastUpdated: getTaiwanTimeString()
                });
              }
              toast.success('已套用模板');
            }}
            clinicId={targetClinicId}
            doctorId={selectedDoctor.doctorId}
          />
        )
      }
    </div >
  );
});

export default ConsultationForm;