import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { collection, query, where, onSnapshot, deleteDoc, doc, orderBy } from 'firebase/firestore';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { db } from './Firebase';
import useUserStore from '../stores/useUserStore';
import { FiCopy, FiTrash } from 'react-icons/fi';

// Add custom scrollbar styles
const customScrollbarStyles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #EDF2F7;
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #CBD5E0;
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #A0AEC0;
  }
`;

const HistoryAndChat = ({ selectedPatient, onViewRecord }) => {
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const { targetClinicId } = useUserStore();

  useEffect(() => {
    let unsubscribe = () => { };

    const fetchMedicalRecords = async () => {
      // 如果沒有選擇病患或診所ID，清空病歷記錄並返回
      if (!selectedPatient || !targetClinicId) {
        setMedicalRecords([]);
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const q = query(
          collection(db, 'MedicalRecords'),
          where('patientId', '==', selectedPatient.patientId),
          where('clinicId', '==', targetClinicId),
          orderBy('lastUpdated', 'desc')
        );

        unsubscribe = onSnapshot(q,
          (snapshot) => {
            const records = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setMedicalRecords(records);
            setLoading(false);
          },
          (error) => {
            console.error('Error in medical records listener:', error);
            toast.error('監聽病歷記錄失敗');
            setLoading(false);
          }
        );
      } catch (error) {
        console.error('Error fetching medical records:', error);
        toast.error('讀取病歷記錄失敗');
        setLoading(false);
      }
    };

    fetchMedicalRecords();

    return () => {
      unsubscribe();
    };
  }, [selectedPatient, targetClinicId]);

  const handleDeleteRecord = async (recordId) => {
    const confirmDelete = window.confirm('確定要刪除此病歷記錄嗎？此操作無法復原。');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'MedicalRecords', recordId));
      toast.success('病歷記錄已刪除');
    } catch (error) {
      console.error('Error deleting record:', error);
      toast.error('刪除病歷記錄失敗');
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case '完成':
        return 'bg-green-100 text-green-800';
      case '草稿':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <>
      <style>{customScrollbarStyles}</style>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-4">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2 text-sm font-medium leading-5
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            病歷記錄
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="relative h-[calc(100vh-200px)] overflow-hidden">
              <div
                className="absolute inset-0 overflow-y-scroll px-2 space-y-4 overflow-x-hidden custom-scrollbar"
                style={{
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#CBD5E0 #EDF2F7',
                }}
              >
                {loading ? (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main"></div>
                  </div>
                ) : !selectedPatient ? (
                  // 新增：當沒有選擇病患時顯示的內容
                  <p className="text-center text-gray-500 py-4">無歷史病歷</p>
                ) : medicalRecords.length === 0 ? (
                  <p className="text-center text-gray-500 py-4">無歷史病歷</p>
                ) : (
                  medicalRecords.map((record) => (
                    <div
                      key={record.id}
                      className="border border-gray-200 rounded-lg p-4 relative bg-white hover:shadow-md transition-shadow duration-200"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm text-gray-500">
                          {moment(record.lastUpdated).format('YYYY-MM-DD HH:mm')}
                        </p>
                        <div className="flex items-center gap-1">
                          <span className={`text-xs px-2 py-1 rounded-full ${getStatusBadgeColor(record.status)}`}>
                            {record.status || '草稿'}
                          </span>
                          <button
                            onClick={() => onViewRecord(record)}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            title="複製病歷"
                          >
                            <FiCopy className="h-4 w-4 text-green-600" />
                          </button>
                          <button
                            onClick={() => handleDeleteRecord(record.id)}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            title="刪除病歷"
                          >
                            <FiTrash className="h-4 w-4 text-red-600" />
                          </button>
                        </div>
                      </div>
                      <div className="space-y-2">
                        <div className="group">
                          <p className="text-l font-medium text-gray-700">{selectedPatient?.userName || '未知'}</p>
                          <p className="text-sm text-gray-500">CC</p>
                          <p className="text-sm group-hover:text-blue-600 transition-colors">{record.complains}</p>
                          <p className="text-sm text-gray-500">PE</p>
                          <p className="text-sm group-hover:text-blue-600 transition-colors">{record.pe}</p>
                        </div>
                        {record.diagnosisCodes?.length > 0 && (
                          <div>
                            <p className="text-sm text-gray-500">診斷碼</p>
                            <div className="flex flex-wrap gap-2">
                              {record.diagnosisCodes.map((code, index) => {
                                const details = record.diagnosisDetails?.[code] || {};
                                return (
                                  <div
                                    key={index}
                                    className="inline-flex flex-col text-sm bg-blue-50 text-blue-700 px-3 py-2 rounded hover:bg-blue-100 transition-colors"
                                  >
                                    <span className="font-medium">{code}</span>
                                    {details.englishName && (
                                      <span className="text-xs text-blue-600">
                                        {details.englishName}
                                      </span>
                                    )}
                                    {details.chineseName && (
                                      <span className="text-xs text-gray-600">
                                        {details.chineseName}
                                      </span>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default HistoryAndChat;