import React, { useState, useEffect, useMemo } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import Modal from './Modal';
import { FiSearch, FiSettings, FiTrash2 } from 'react-icons/fi';
import debounce from 'lodash/debounce';
import { db } from '../Firebase';
import { Link } from 'react-router-dom';

const SelectMedicalRecordTemplateModal = ({
  isOpen,
  closeModal,
  onSelect,
  clinicId,
  doctorId
}) => {
  // 狀態管理
  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  // 加載模板的副作用函數
  useEffect(() => {
    const loadTemplates = async () => {
      if (!clinicId || !doctorId) return;

      try {
        setIsLoading(true);
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctor = clinicData.doctors?.find(d => d.doctorId === doctorId);

          // 安全地獲取模板，確保是陣列
          const medicalRecordTemplates = Array.isArray(doctor?.medicalRecordTemplates)
            ? doctor.medicalRecordTemplates
            : [];

          setTemplates(medicalRecordTemplates);
        }
      } catch (error) {
        console.error('Error loading templates:', error);
        toast.error('載入模板失敗');
        setTemplates([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      loadTemplates();
    }
  }, [isOpen, clinicId, doctorId]);

  // 刪除模板的處理函數
  const handleDelete = async (templateName) => {
    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (!clinicDoc.exists()) {
        throw new Error('找不到診所資料');
      }

      const clinicData = clinicDoc.data();
      const doctors = clinicData.doctors || [];
      const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

      if (doctorIndex === -1) {
        throw new Error('找不到醫師資料');
      }

      // 過濾出要刪除的模板
      const updatedTemplates = doctors[doctorIndex].medicalRecordTemplates.filter(
        t => t.name !== templateName
      );

      // 更新醫師的模板
      const updatedDoctors = [...doctors];
      updatedDoctors[doctorIndex] = {
        ...updatedDoctors[doctorIndex],
        medicalRecordTemplates: updatedTemplates
      };

      await updateDoc(clinicRef, { doctors: updatedDoctors });
      setTemplates(updatedTemplates);
      setDeleteConfirm(null);
      toast.success('模板已刪除');
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('刪除模板失敗');
    }
  };

  // 使用 useMemo 優化模板篩選
  const filteredTemplates = useMemo(() => {
    if (!searchTerm) return templates;

    const lowercaseSearchTerm = searchTerm.toLowerCase();

    return templates.filter(template => {
      // 安全地檢查模板各個欄位
      const nameMatch = template.name &&
        template.name.toLowerCase().includes(lowercaseSearchTerm);

      const complainsMatch = template.complains &&
        template.complains.toLowerCase().includes(lowercaseSearchTerm);

      const diagnosisCodeMatch = template.diagnosisCodes &&
        template.diagnosisCodes.some(code =>
          template.diagnosisDetails?.[code]?.chineseName?.toLowerCase().includes(lowercaseSearchTerm)
        );

      const medicalServiceMatch = template.medicalServices &&
        template.medicalServices.some(serviceId =>
          template.medicalServiceDetails?.[serviceId]?.chineseName?.toLowerCase().includes(lowercaseSearchTerm)
        );

      return nameMatch || complainsMatch || diagnosisCodeMatch || medicalServiceMatch;
    });
  }, [templates, searchTerm]);

  // 防抖搜尋
  const handleSearch = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  // 渲染模板內容的輔助函數
  const renderTemplatePreview = (template) => {
    return (
      <div className="space-y-3">
        {template.complains && (
          <div className="text-sm">
            <span className="font-medium">主訴: </span>
            <span className="text-gray-600">{template.complains}</span>
          </div>
        )}

        {template.pe && (
          <div className="text-sm">
            <span className="font-medium">PE: </span>
            <span className="text-gray-600">{template.pe}</span>
          </div>
        )}

        {template.diagnosisCodes && template.diagnosisCodes.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {template.diagnosisCodes.map(code => (
              <div
                key={code}
                className="text-xs bg-blue-50 px-2 py-1 rounded"
              >
                {code} - {template.diagnosisDetails?.[code]?.chineseName || '未知'}
              </div>
            ))}
          </div>
        )}

        {template.medicalServices && template.medicalServices.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {template.medicalServices.map(serviceId => (
              <div
                key={serviceId}
                className="text-xs bg-green-50 px-2 py-1 rounded"
              >
                {serviceId} - {template.medicalServiceDetails?.[serviceId]?.chineseName || '未知'}
                {template.medicalServicePayments?.[serviceId] && (
                  <span className="ml-1 text-blue-600">
                    ({template.medicalServicePayments[serviceId]})
                  </span>
                )}
              </div>
            ))}
          </div>
        )}

        {template.medications && template.medications.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {template.medications.map((med, idx) => (
              <div
                key={idx}
                className="text-xs bg-yellow-50 px-2 py-1 rounded"
              >
                {med.englishName} {med.chineseName} - {med.dosage} {med.frequency}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="選擇病歷模板"
      width="max-w-3xl"
    >
      <div className="space-y-6">
        {/* 搜尋輸入框 */}
        <div className="relative">
          <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="搜尋模板名稱或內容..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <div></div>
          <Link
            to="/settings"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
          >
            <FiSettings className="w-5 h-5" />
            管理模板
          </Link>
        </div>

        {/* 模板列表 */}
        {isLoading ? (
          <div className="flex justify-center items-center h-48">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : filteredTemplates.length > 0 ? (
          <div className="max-h-[60vh] overflow-y-auto space-y-4">
            {filteredTemplates.map((template, index) => (
              <div
                key={index}
                className="p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors relative group"
              >
                {/* 模板標題和操作 */}
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-medium text-lg">{template.name}</h3>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-500">
                      {new Date(template.createdAt).toLocaleDateString()}
                    </span>
                    {deleteConfirm === template.name ? (
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleDelete(template.name)}
                          className="text-red-500 hover:text-red-700"
                        >
                          確定刪除
                        </button>
                        <button
                          onClick={() => setDeleteConfirm(null)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          取消
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => setDeleteConfirm(template.name)}
                        className="text-gray-400 hover:text-red-500 transition-colors opacity-0 group-hover:opacity-100"
                      >
                        <FiTrash2 className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>

                {/* 模板內容預覽 */}
                {renderTemplatePreview(template)}

                {/* 套用模板按鈕 */}
                <button
                  className="mt-3 px-4 py-2 text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors w-full"
                  onClick={() => {
                    // 安全地傳遞模板數據
                    onSelect({
                      complains: template.complains || '',
                      pe: template.pe || '',
                      diagnosisCodes: template.diagnosisCodes || [],
                      diagnosisDetails: template.diagnosisDetails || {},
                      medicalServices: template.medicalServices || [],
                      medicalServiceDetails: template.medicalServiceDetails || {},
                      medicalServicePayments: template.medicalServicePayments || {},
                      medications: template.medications || []
                    });
                    closeModal();
                  }}
                >
                  套用此模板
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 text-gray-500">
            {searchTerm ? '找不到符合的模板' : '尚未建立任何模板'}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SelectMedicalRecordTemplateModal;