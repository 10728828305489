import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Configuration for calculation rules
const CALCULATION_RULES = {
    // Special service IDs that have multipliers
    specialServiceMultipliers: {
        // For example: diagnostic procedures might have a 1.2x multiplier
        // Format: 'serviceId': multiplierValue
        // '12001': 1.2,
        // '12002': 1.5,
        // '12003': 1.3,
        // Add more as needed
    },

    // Multipliers based on payment type
    paymentTypeMultipliers: {
        '健保': 1.0,  // Regular insurance - base rate
        '自費': 0,    // Self-paid - not included in main calculation
        '急診': 1.3,  // Emergency - higher rate
    },

    // Service ID prefixes that should be grouped together (e.g., all 1xxxx codes)
    serviceGroups: [
        {
            prefix: '6', // For example: all codes starting with 120
            name: '手術',   // Group name
            multiplier: 1.35,  // Special multiplier for this group
        },
        // {
        //   prefix: '230', 
        //   name: '治療類',
        //   multiplier: 1.2,
        // },
    ],
};

const MedicalServicesSummary = ({
    medicalServices = [],
    medicalServiceDetails = {},
    medicalServicePayments = {},
    customRules = null, // Allow for rule overrides
    onSummaryCalculated = null // Add callback to send calculated values to parent
}) => {
    const [summary, setSummary] = useState({
        insuranceCredits: 0,      // 健保 + 急診
        selfPaidCredits: 0,       // 自費
        groupTotals: {},
        paymentTypeTotals: {},
        adjustedTotal: 0
    });

    // Use provided rules or fallback to default
    const rules = customRules || CALCULATION_RULES;

    useEffect(() => {
        // Skip calculation if no services
        if (!medicalServices || medicalServices.length === 0) {
            const emptySummary = {
                insuranceCredits: 0,
                selfPaidCredits: 0,
                groupTotals: {},
                paymentTypeTotals: {},
                adjustedTotal: 0
            };
            setSummary(emptySummary);
            
            // Call the callback with calculated values if provided
            if (onSummaryCalculated) {
                onSummaryCalculated(emptySummary);
            }
            return;
        }
    
        // Initialize calculation variables
        let insuranceCredits = 0;
        let selfPaidCredits = 0;
        let adjustedTotal = 0;
        const groupTotals = {};
        const paymentTypeTotals = {};
    
        // Initialize group totals
        rules.serviceGroups.forEach(group => {
            groupTotals[group.name] = 0;
        });
    
        // Initialize payment type totals
        Object.keys(rules.paymentTypeMultipliers).forEach(type => {
            paymentTypeTotals[type] = 0;
        });
    
        // First pass: calculate raw totals by group and payment type
        medicalServices.forEach(serviceId => {
            const details = medicalServiceDetails[serviceId] || {};
            const paymentType = medicalServicePayments[serviceId] || '健保'; // Default to regular insurance
            const credits = parseFloat(details.credits) || 0;
    
            // Skip if no credits
            if (credits <= 0) return;
    
            // Add to payment type totals
            if (paymentTypeTotals[paymentType] !== undefined) {
                paymentTypeTotals[paymentType] += credits;
            }
    
            // Separate calculation for insurance (健保 + 急診) and self-paid (自費)
            if (paymentType === '自費') {
                selfPaidCredits += credits;
            } else {
                // For 健保 and 急診
                insuranceCredits += credits;
            }
    
            // Check if this service belongs to any group
            rules.serviceGroups.forEach(group => {
                if (serviceId.startsWith(group.prefix)) {
                    groupTotals[group.name] += credits;
                }
            });
        });
    
        // Apply special service multipliers and payment type multipliers only to insurance credits
        let specialServicesTotal = 0;
        let emergencyAdjustment = 0;
    
        // Apply group multipliers
        rules.serviceGroups.forEach(group => {
            if (groupTotals[group.name] > 0) {
                specialServicesTotal += groupTotals[group.name] * (group.multiplier - 1);
            }
        });
    
        // Apply emergency multiplier to 急診 services
        if (paymentTypeTotals['急診'] > 0) {
            const multiplier = rules.paymentTypeMultipliers['急診'] || 1;
            emergencyAdjustment = paymentTypeTotals['急診'] * (multiplier - 1);
        }
    
        // Calculate final adjusted total (only for insurance services)
        adjustedTotal = insuranceCredits + specialServicesTotal + emergencyAdjustment;
    
        // Create the new summary
        const newSummary = {
            insuranceCredits,
            selfPaidCredits,
            groupTotals,
            paymentTypeTotals,
            specialServicesAdjustment: specialServicesTotal,
            emergencyAdjustment,
            adjustedTotal
        };
    
        // Check if the summary actually changed before updating
        const hasChanged = JSON.stringify(summary) !== JSON.stringify(newSummary);
        
        if (hasChanged) {
            // Update summary state
            setSummary(newSummary);
            
            // Call the callback with calculated values if provided
            if (onSummaryCalculated) {
                onSummaryCalculated(newSummary);
            }
        }
    }, [medicalServices, medicalServiceDetails, medicalServicePayments, customRules]);

    // // No services, show empty message
    // if (!medicalServices || medicalServices.length === 0) {
    //     return (
    //         <div className="mt-4 p-4 bg-gray-50 rounded-lg">
    //             <p className="text-gray-500 italic">尚未選擇任何醫療服務項目，無法計算點數</p>
    //         </div>
    //     );
    // }

    return (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-3">醫令點數統計</h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left column: Totals */}
                <div>
                    <div className="mb-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">健保點數統計</h4>
                        <div className="bg-white p-3 rounded border border-gray-200">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-800">健保與急診點數</span>
                                <span className="font-medium">{(summary.insuranceCredits ?? 0).toFixed(2)}</span>
                            </div>

                            {/* Emergency adjustments */}
                            {summary.emergencyAdjustment > 0 && (
                                <div className="flex justify-between items-center mt-1 text-sm">
                                    <span className="text-gray-600">急診加成</span>
                                    <span className="font-medium text-blue-600">+{(summary.emergencyAdjustment ?? 0).toFixed(2)}</span>
                                </div>
                            )}

                            {/* Special service adjustments */}
                            {summary.specialServicesAdjustment > 0 && (
                                <div className="flex justify-between items-center mt-1 text-sm">
                                    <span className="text-gray-600">特殊服務加成</span>
                                    <span className="font-medium text-blue-600">+{(summary.specialServicesAdjustment ?? 0).toFixed(2)}</span>
                                </div>
                            )}

                            {/* Total after adjustments */}
                            <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200">
                                <span className="text-gray-800 font-medium">調整後總計</span>
                                <span className="font-bold text-lg text-green-600">{(summary.adjustedTotal ?? 0).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right column: Breakdowns */}
                <div>
                    {/* Payment type totals */}
                    <div>
                        <h4 className="text-sm font-medium text-gray-700 mb-2">給付類型統計</h4>
                        <div className="bg-white p-3 rounded border border-gray-200">
                            {/* Only show 健保 and 自費 */}
                            {summary.paymentTypeTotals['健保'] > 0 && (
                                <div className="flex justify-between items-center mb-1">
                                    <span className="text-gray-800">健保</span>
                                    <span className="font-medium">{(summary.adjustedTotal ?? 0).toFixed(2)}</span>
                                </div>
                            )}

                            {summary.paymentTypeTotals['自費'] > 0 && (
                                <div className="flex justify-between items-center mb-1">
                                    <span className="text-gray-800">自費</span>
                                    <span className="font-medium">{summary.paymentTypeTotals['自費'].toFixed(2)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MedicalServicesSummary.propTypes = {
    medicalServices: PropTypes.array,
    medicalServiceDetails: PropTypes.object,
    medicalServicePayments: PropTypes.object,
    customRules: PropTypes.object,
    onSummaryCalculated: PropTypes.func
};

export default MedicalServicesSummary;