import React, { useState, useEffect, useRef } from 'react';
import { ref, getMetadata, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { storage, db } from './Firebase';
import Papa from 'papaparse';
import { toast } from 'react-hot-toast';
import Fuse from 'fuse.js';
import { FiRefreshCw, FiSearch, FiX } from 'react-icons/fi';
import debounce from 'lodash/debounce';
import SaveMedicalServiceTemplateModal from './Modals/SaveMedicalServiceTemplateModal';
import SelectMedicalServiceTemplateModal from './Modals/SelectMedicalServiceTemplateModal';

const DB_NAME = 'MedicalServicesDB';
const DB_VERSION = 1;
const STORE_NAME = 'medicalServices';
const META_STORE_NAME = 'metadataServices';

const MedicalServiceSelector = ({
  onSelect,
  clinicId,
  doctorId,
  isActive = false,
  onDropdownOpen = () => { },
  onDropdownClose = () => { },
  zIndex = 10
}) => {
  // State Management
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dbStatus, setDbStatus] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [frequentServices, setFrequentServices] = useState([]);
  const [showFrequentServices, setShowFrequentServices] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  // Refs
  const fuseRef = useRef(null);
  const localDBRef = useRef(null);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const selectedItemRef = useRef(null);

  useEffect(() => {
    // Add a default service when component mounts
    const addDefaultService = async () => {
      // Only proceed if we have the necessary IDs and the component is ready
      if (!isLoading && clinicId && doctorId && localDBRef.current) {
        try {
          // Check if we already have services selected
          if (selectedServices.length === 0) {
            // Get the default service from IndexedDB
            const transaction = localDBRef.current.transaction([STORE_NAME], 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.get('00109C');

            request.onsuccess = () => {
              if (request.result) {
                // Found the default service, add it
                const defaultService = request.result;
                handleSelect(defaultService);
              } else {
                console.log('Default service 00109C not found in database');
              }
            };
          }
        } catch (error) {
          console.error('Error adding default service:', error);
        }
      }
    };

    addDefaultService();
  }, [isLoading, localDBRef.current, clinicId, doctorId, selectedServices.length]);

  // Initialize IndexedDB
  useEffect(() => {
    let isMounted = true;
    let dbInstance = null;

    const initDB = async () => {
      try {
        setIsLoading(true);
        dbInstance = await initializeDatabase();

        if (isMounted) {
          localDBRef.current = dbInstance;
          await checkForUpdates(); // Check for updates after initializing the database
        }
      } catch (error) {
        console.error('Database initialization failed:', error);
        if (isMounted) {
          toast.error('初始化資料庫失敗，請重新載入頁面');
          setDbStatus('資料庫錯誤');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    initDB();

    // Cleanup function
    return () => {
      isMounted = false;
      if (dbInstance) {
        dbInstance.close();
      }
    };
  }, []);

  // Load frequent services on mount
  useEffect(() => {
    loadFrequentServicesFromFirebase();
  }, [clinicId, doctorId]);

  useEffect(() => {
    const loadCustomServices = async () => {
      if (!clinicId) return;

      try {
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const customServices = clinicData.customServices || [];

          // If we have custom services and Fuse is initialized
          if (customServices.length > 0 && fuseRef.current) {
            // Add custom services to Fuse index
            customServices.forEach(service => {
              fuseRef.current.add(service);
            });
          }
        }
      } catch (error) {
        console.error('Error loading custom services:', error);
      }
    };

    if (!isLoading && localDBRef.current) {
      loadCustomServices();
    }
  }, [clinicId, isLoading, localDBRef.current]);

  // Add scroll detection
  useEffect(() => {
    if (suggestionsRef.current) {
      setIsScrollable(
        suggestionsRef.current.scrollHeight > suggestionsRef.current.clientHeight
      );
    }
  }, [suggestions]);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedItemRef.current && suggestionsRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedIndex]);
  // Database initialization function
  const initializeDatabase = () => {
    return new Promise((resolve, reject) => {
      let openRequest;
      try {
        openRequest = indexedDB.open(DB_NAME, DB_VERSION);
      } catch (error) {
        console.error('Failed to open IndexedDB:', error);
        reject(error);
        return;
      }

      openRequest.onerror = (event) => {
        console.error("Database error:", event.target.error);
        reject(event.target.error);
      };

      openRequest.onblocked = (event) => {
        console.warn("Database blocked, please close other tabs");
        if (openRequest.result) {
          openRequest.result.close();
        }
      };

      openRequest.onupgradeneeded = (event) => {
        const db = event.target.result;

        if (!db.objectStoreNames.contains(STORE_NAME)) {
          const store = db.createObjectStore(STORE_NAME, { keyPath: 'id' });
          store.createIndex('chineseName', 'chineseName', { unique: false });
          store.createIndex('englishName', 'englishName', { unique: false });
        }

        if (!db.objectStoreNames.contains(META_STORE_NAME)) {
          db.createObjectStore(META_STORE_NAME, { keyPath: 'key' });
        }
      };

      openRequest.onsuccess = (event) => {
        const db = event.target.result;
        db.onversionchange = () => {
          db.close();
          console.log('Database is outdated, please reload the page.');
          toast.error('資料庫版本已更新，請重新載入頁面');
        };
        resolve(db);
      };
    });
  };
  // Download and process medical services from Firebase Storage
  const downloadAndProcessServices = async () => {
    try {
      const storageRef = ref(storage, 'medical-service-data/service-codes.csv');
      const url = await getDownloadURL(storageRef);
      const metadata = await getMetadata(storageRef);

      const response = await fetch(url);
      const text = await response.text();

      const results = Papa.parse(text, {
        header: true,
        skipEmptyLines: true
      });

      const uniqueIds = new Set();
      const services = results.data
        .filter(row => {
          if (!row.id || uniqueIds.has(row.id)) return false;
          uniqueIds.add(row.id);
          return true;
        })
        .map(row => ({
          id: row.id,
          chineseName: row.chineseName,
          englishName: row.englishName,
          credits: row.credits,
          dateStart: row.dateStart,
          dateEnd: row.dateEnd,
          notes: row.notes,
        }));

      await saveServicesToDB(services, metadata.customMetadata);
      initializeFuse(services);
      setDbStatus(`已更新 ${services.length} 筆服務資料`);
    } catch (error) {
      console.error('Error downloading services:', error);
      throw error;
    }
  };

  // Save services to IndexedDB
  const saveServicesToDB = async (services, metadata) => {
    if (!localDBRef.current) {
      throw new Error('Database not initialized');
    }

    const BATCH_SIZE = 1000;
    const totalBatches = Math.ceil(services.length / BATCH_SIZE);

    try {
      // Clear existing data
      const clearTransaction = localDBRef.current.transaction([STORE_NAME], 'readwrite');
      const store = clearTransaction.objectStore(STORE_NAME);

      await new Promise((resolve, reject) => {
        const clearRequest = store.clear();
        clearRequest.onsuccess = resolve;
        clearRequest.onerror = () => reject(clearRequest.error);
      });

      // Insert new data in batches
      for (let i = 0; i < totalBatches; i++) {
        const start = i * BATCH_SIZE;
        const end = Math.min(start + BATCH_SIZE, services.length);
        const batch = services.slice(start, end);

        await new Promise((resolve, reject) => {
          const batchTransaction = localDBRef.current.transaction([STORE_NAME], 'readwrite');
          const store = batchTransaction.objectStore(STORE_NAME);

          batchTransaction.oncomplete = resolve;
          batchTransaction.onerror = (event) => reject(event.target.error);

          batch.forEach(service => {
            if (service.id) {
              store.put({
                ...service,
                updatedAt: new Date().toISOString()
              });
            }
          });
        });

        setDbStatus(`已儲存 ${end} / ${services.length} 筆資料`);
      }

      // Update metadata
      const metaTransaction = localDBRef.current.transaction([META_STORE_NAME], 'readwrite');
      const metaStore = metaTransaction.objectStore(META_STORE_NAME);

      await new Promise((resolve, reject) => {
        const metaRequest = metaStore.put({
          key: 'version',
          checksum: metadata.fileChecksum,
          updatedAt: new Date().toISOString(),
          totalRecords: services.length
        });

        metaRequest.onsuccess = resolve;
        metaRequest.onerror = () => reject(metaRequest.error);
      });
    } catch (error) {
      console.error('Error saving to IndexedDB:', error);
      throw new Error('儲存資料失敗: ' + error.message);
    }
  };

  // Check for updates
  const checkForUpdates = async () => {
    try {
      setIsLoading(true);
      setDbStatus('檢查更新中...');

      const lastUpdateCheck = localStorage.getItem('lastServiceUpdateCheck');
      const currentTime = Date.now();

      // Check if local data exists
      const localMetadata = await getLocalMetadata();

      // If no local data exists, download the data immediately
      if (!localMetadata) {
        setDbStatus('下載資料中...');
        await downloadAndProcessServices();
        localStorage.setItem('lastServiceUpdateCheck', currentTime.toString());
        return;
      }

      // If 24 hours have passed since the last update check
      if (!lastUpdateCheck || (currentTime - parseInt(lastUpdateCheck, 10)) > 24 * 60 * 60 * 1000) {
        const storageRef = ref(storage, 'medical-service-data/service-codes.csv');
        const metadata = await getMetadata(storageRef);

        if (localMetadata.checksum !== metadata.customMetadata.fileChecksum) {
          setDbStatus('發現新版本，更新中...');
          await downloadAndProcessServices();
        } else {
          setDbStatus('資料已是最新版本');
        }

        localStorage.setItem('lastServiceUpdateCheck', currentTime.toString());
      } else {
        setDbStatus('載入本地資料...');
        await loadLocalServices();
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
      toast.error('檢查更新失敗，使用本地資料');
      await loadLocalServices();
    } finally {
      setIsLoading(false);
    }
  };

  // Get local metadata
  const getLocalMetadata = async () => {
    return new Promise((resolve, reject) => {
      const transaction = localDBRef.current.transaction([META_STORE_NAME], 'readonly');
      const store = transaction.objectStore(META_STORE_NAME);
      const request = store.get('version');

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  };

  // Load services from local DB
  const loadLocalServices = async () => {
    return new Promise((resolve, reject) => {
      const transaction = localDBRef.current.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.getAll();

      request.onsuccess = () => {
        initializeFuse(request.result);
        const lastUpdateCheck = localStorage.getItem('lastUpdateCheck');
        const lastUpdateTime = lastUpdateCheck ? new Date(parseInt(lastUpdateCheck, 10)).toLocaleString() : '無記錄';
        setDbStatus(`資料最後更新時間：${lastUpdateTime}`);
        resolve(request.result);
      };
      request.onerror = () => reject(request.error);
    });
  };
  // Firebase Integration Methods
  const loadFrequentServicesFromFirebase = async () => {
    if (!clinicId || !doctorId) return;

    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists()) {
        const clinicData = clinicDoc.data();
        const doctors = clinicData.doctors || [];
        const currentDoctor = doctors.find(d => d.doctorId === doctorId);

        if (currentDoctor?.frequentServices) {
          setFrequentServices(currentDoctor.frequentServices);
        } else {
          const localServices = localStorage.getItem(`frequentServices_${doctorId}`);
          if (localServices) {
            setFrequentServices(JSON.parse(localServices));
          }
        }
      }
    } catch (error) {
      console.error('Error loading frequent services:', error);
      const localServices = localStorage.getItem(`frequentServices_${doctorId}`);
      if (localServices) {
        setFrequentServices(JSON.parse(localServices));
      }
    }
  };

  const updateFrequentServices = async (service) => {
    try {
      const newFrequentServices = [service, ...frequentServices]
        .filter((s, index, self) =>
          index === self.findIndex(m => m.id === s.id)
        )
        .slice(0, 20);

      setFrequentServices(newFrequentServices);
      localStorage.setItem(
        `frequentServices_${doctorId}`,
        JSON.stringify(newFrequentServices)
      );

      if (clinicId && doctorId) {
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctors = clinicData.doctors || [];
          const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

          if (doctorIndex !== -1) {
            doctors[doctorIndex] = {
              ...doctors[doctorIndex],
              frequentServices: newFrequentServices
            };

            await updateDoc(clinicRef, { doctors });
          }
        }
      }
    } catch (error) {
      console.error('Error updating frequent services:', error);
      toast.error('更新常用服務失敗');
    }
  };

  // Search and Selection Methods
  const initializeFuse = (services) => {
    fuseRef.current = new Fuse(services, {
      keys: ['id', 'englishName', 'chineseName'],
      threshold: 0.3,
      includeScore: true,
      findAllMatches: true,
      ignoreLocation: true,
      // Add useExtendedSearch option to better handle custom data format differences
      useExtendedSearch: true
    });

    // If we have a clinic, also load custom services into the index
    if (clinicId) {
      loadCustomServicesIntoFuse();
    }
  };

  // Add this helper function
  const loadCustomServicesIntoFuse = async () => {
    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists() && fuseRef.current) {
        const clinicData = clinicDoc.data();
        const customServices = clinicData.customServices || [];

        // Add each custom service to the Fuse index
        customServices.forEach(service => {
          fuseRef.current.add(service);
        });
      }
    } catch (error) {
      console.error('Error loading custom services into search index:', error);
    }
  };

  const handleSearch = debounce((term) => {
    if (!term) {
      setSuggestions(frequentServices.slice(0, 10));
      return;
    }

    if (fuseRef.current) {
      // Get results from Fuse search
      const results = fuseRef.current.search(term)
        .slice(0, 10)
        .map(result => result.item);

      // If there are few results and we have clinicId, add custom search
      if (results.length < 5 && clinicId) {
        // Try to get custom services asynchronously
        const loadCustomSearchResults = async () => {
          try {
            const clinicRef = doc(db, 'OwnedClinic', clinicId);
            const clinicDoc = await getDoc(clinicRef);

            if (clinicDoc.exists()) {
              const clinicData = clinicDoc.data();
              const customServices = clinicData.customServices || [];

              // Filter custom services by search term
              const filteredCustom = customServices.filter(service =>
                service.id.toLowerCase().includes(term.toLowerCase()) ||
                service.englishName.toLowerCase().includes(term.toLowerCase()) ||
                service.chineseName.toLowerCase().includes(term.toLowerCase())
              );

              // Merge results, prioritizing custom services
              if (filteredCustom.length > 0) {
                const combinedResults = [...filteredCustom, ...results]
                  // Remove duplicates by ID
                  .filter((service, index, self) =>
                    index === self.findIndex(s => s.id === service.id)
                  )
                  .slice(0, 10);

                setSuggestions(combinedResults);
              } else {
                setSuggestions(results);
              }
            } else {
              setSuggestions(results);
            }
          } catch (error) {
            console.error('Error in custom service search:', error);
            setSuggestions(results);
          }
        };

        loadCustomSearchResults();
      } else {
        setSuggestions(results);
      }

      setSelectedIndex(0);
    }
  }, 150);

  const handleSelect = async (service) => {
    if (!service) return;
    try {

      // Update local state
      setSelectedServices(prev => [...prev, service]);

      // Call parent's onSelect
      onSelect?.(service, false); // passing false for isTemplate

      await updateFrequentServices(service);
      setSearchTerm(''); // Clear the search term
      setSuggestions(frequentServices.slice(0, 10)); // Reset to frequent services
      setShowSuggestions(true); // Keep suggestions visible

      // Maintain focus on the input field
      inputRef.current?.focus();

      toast.success(`已選擇: ${service.chineseName}`);
    } catch (error) {
      console.error('Error selecting service:', error);
      toast.error('新增服務失敗');
    }
  };

  const removeService = (serviceId) => {
    setSelectedServices(prev => prev.filter(service => service.id !== serviceId));
  };

  // UI Interaction Methods
  const handleFocus = () => {
    onDropdownOpen();
    if (!searchTerm && frequentServices.length > 0) {
      setShowFrequentServices(true);
      setSuggestions(frequentServices.slice(0, 10));
    }
    setShowSuggestions(true);
  };

  const handleCloseSuggestions = () => {
    onDropdownClose();
    setShowSuggestions(false);
    setShowFrequentServices(false);
    setSearchTerm('');
    setSuggestions([]);
  };

  const handleTemplateSelect = (templateServices) => {
    if (!Array.isArray(templateServices)) {
      console.error('Template services must be an array');
      return;
    }

    // Filter out services that are already in the selectedServices array
    const newServices = templateServices.filter(
      templateService => !selectedServices.some(
        selectedService => selectedService.id === templateService.id
      )
    );

    if (newServices.length === 0) {
      toast.info('所有模板項目已經添加過了');
      return;
    }

    // Update local state
    setSelectedServices(prev => [...prev, ...newServices]);

    // Call parent handler with all services
    onSelect?.(newServices, true);

    toast.success(`已套用模板 (新增了 ${newServices.length} 個項目)`);
  };

  const handleKeyDown = (e) => {
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (suggestions[selectedIndex]) {
          handleSelect(suggestions[selectedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        handleCloseSuggestions();
        break;
      default:
        // For any other key, we ensure suggestions remain visible
        if (!showSuggestions) {
          setShowSuggestions(true);
        }
        break;
    }
  };

  // UI Rendering
  return (
    <div className="space-y-4">
      {/* Search Input Section */}
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <div className="relative">
            <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value);
              }}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder="搜尋醫療服務代碼或名稱..."
              className="w-full pl-10 pr-20 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              disabled={isLoading}
            />
            {(showSuggestions || showFrequentServices) && (
              <button
                onClick={handleCloseSuggestions}
                className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <FiX className="w-4 h-4" />
              </button>
            )}
            <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-sm">
              {dbStatus}
            </div>
          </div>
        </div>
        <button
          onClick={checkForUpdates}
          disabled={isRefreshing || isLoading}
          className={`p-2 rounded-lg border border-gray-300 hover:bg-gray-50 
              ${(isRefreshing || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          title="更新醫療服務資料庫"
        >
          <FiRefreshCw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
        </button>
      </div>
      {/* Template Controls */}
      {/* Template Controls */}
      <div className="flex justify-end gap-2 mb-2">
        <button
          onClick={() => setShowSelectTemplateModal(true)}
          className="px-3 py-1 text-sm text-blue-600 bg-blue-50 rounded hover:bg-blue-100 transition-colors"
        >
          載入醫令模板
        </button>
        <button
          onClick={() => setShowSaveTemplateModal(true)}
          disabled={!selectedServices.length}
          className="px-3 py-1 text-sm text-green-600 bg-green-50 rounded hover:bg-green-100 transition-colors disabled:opacity-50"
        >
          儲存為醫令模板
        </button>
      </div>

      {/* Suggestions Section */}
      {showSuggestions && suggestions.length > 0 && (
        <div className="relative">
          <button
            onClick={handleCloseSuggestions}
            className="absolute -top-2 -right-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
            title="關閉建議"
          >
            <FiX className="w-4 h-4 text-gray-600" />
          </button>
          <div
            ref={suggestionsRef}
            className="absolute z-40 w-full mt-1 bg-white border border-gray-200 
                       rounded-lg shadow-lg max-h-60 overflow-y-auto 
                       scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
            style={{ zIndex: zIndex }}

          >
            {showFrequentServices && (
              <div className="px-4 py-2 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                <span className="text-sm font-medium text-gray-600">最近使用的醫療服務</span>
                <button
                  onClick={() => setShowFrequentServices(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <FiX className="w-4 h-4" />
                </button>
              </div>
            )}

            {suggestions.map((service, index) => (
              <div
                key={service.id}
                ref={index === selectedIndex ? selectedItemRef : null}
                onClick={() => handleSelect(service)}
                className={`w-full px-4 py-2 text-left cursor-pointer hover:bg-gray-50 
                             flex flex-col border-b border-gray-100
                             ${index === selectedIndex ? 'bg-blue-50' : ''}`}
              >
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-blue-600">{service.id}</span>
                  <span className="text-sm text-gray-500">|</span>
                  <span className="font-medium">{service.englishName} {service.chineseName} </span>
                </div>
              </div>
            ))}
          </div>

          {/* Scroll indicator */}
          {isScrollable && (
            <div className="absolute right-0 top-1 bottom-1 w-1 z-50">
              <div className="h-full w-full bg-gray-200 rounded-r-lg">
                <div
                  className="w-full bg-gray-400 rounded-r-lg opacity-50 
                               transition-all duration-300
                               hover:opacity-75 hover:w-1.5"
                  style={{
                    height: '30%',
                    transform: `translateY(${(selectedIndex / suggestions.length) * 70}%)`
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {/* Template Modals */}
      {showSaveTemplateModal && (
        <SaveMedicalServiceTemplateModal
          isOpen={showSaveTemplateModal}
          closeModal={() => setShowSaveTemplateModal(false)}
          services={selectedServices}
          clinicId={clinicId}
          doctorId={doctorId}
        />
      )}
      {showSelectTemplateModal && (
        <SelectMedicalServiceTemplateModal
          isOpen={showSelectTemplateModal}
          closeModal={() => setShowSelectTemplateModal(false)}
          onSelect={handleTemplateSelect}
          clinicId={clinicId}
          doctorId={doctorId}
        />
      )}
    </div>
  );
};

export default MedicalServiceSelector;