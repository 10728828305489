import React, { useState, useEffect, useRef } from 'react';
import { ref, getMetadata, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { storage, db } from './Firebase';
import Papa from 'papaparse';
import { toast } from 'react-hot-toast';
import Fuse from 'fuse.js';
import { FiRefreshCw, FiSearch, FiX } from 'react-icons/fi';
import debounce from 'lodash/debounce';
import SaveMedicineTemplateModal from './Modals/SaveMedicineTemplateModal';
import SelectMedicineTemplateModal from './Modals/SelectMedicineTemplateModal';

// Default options for medication properties
const DOSAGE_OPTIONS = ['1顆', '2顆', '3顆', '半顆', '1包', '2包', '5ml', '10ml'];
const FREQUENCY_OPTIONS = ['每天1次', '每天2次', '每天3次', '每4小時1次', '每6小時1次', '睡前服用'];
const DURATION_OPTIONS = ['1天', '3天', '5天', '7天', '10天', '14天', '28天'];
const PAYMENT_OPTIONS = ['健保', '自費', '不申報'];
const REFILL_OPTIONS = ['0', '1', '2', '3'];

const MedicineSearch = ({
  medications = [],
  onChange,
  clinicId,
  doctorId,
  isActive = false,
  onDropdownOpen = () => { },
  onDropdownClose = () => { },
  zIndex = 10,
  refillCount = '0', // Add refillCount as a prop
  onRefillCountChange = () => { } // Add handler for refill count changes
}) => {
  // State Management
  const [manualInput, setManualInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dbStatus, setDbStatus] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [frequentMedicines, setFrequentMedicines] = useState([]);
  const [showFrequentMedicines, setShowFrequentMedicines] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [hasLocalData, setHasLocalData] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);

  // Tab state
  const [activeTab, setActiveTab] = useState('general'); // 'general' or 'chronic'

  // Refs
  const fuseRef = useRef(null);
  const localDBRef = useRef(null);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const selectedItemRef = useRef(null);

  // Initialize IndexedDB on mount
  useEffect(() => {
    const initDB = () => {
      const request = indexedDB.open('MedicinesDB', 1);

      request.onerror = () => {
        console.error("IndexedDB error");
        toast.error("無法初始化本地資料庫");
        setIsLoading(false);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('medicines')) {
          const store = db.createObjectStore('medicines', { keyPath: 'id' });
          store.createIndex('chineseName', 'chineseName', { unique: false });
          store.createIndex('englishName', 'englishName', { unique: false });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      request.onsuccess = async (event) => {
        localDBRef.current = event.target.result;

        // Check if we have any data
        const hasData = await checkLocalData();
        setHasLocalData(hasData);

        if (!hasData) {
          // First time use - download data
          await performInitialDownload();
        } else {
          // Check for updates if enough time has passed
          await checkForUpdates();
        }

        setIsInitialLoad(false);
        setIsLoading(false);
      };
    };

    initDB();
  }, []);

  const checkLocalData = async () => {
    return new Promise((resolve) => {
      const transaction = localDBRef.current.transaction(['medicines'], 'readonly');
      const store = transaction.objectStore('medicines');
      const countRequest = store.count();

      countRequest.onsuccess = () => {
        resolve(countRequest.result > 0);
      };

      countRequest.onerror = () => {
        console.error('Error checking local data count');
        resolve(false);
      };
    });
  };

  const performInitialDownload = async () => {
    try {
      setIsLoading(true); // 確保一開始設置loading狀態
      setDbStatus('下載資料中...');
      await downloadAndProcessMedicines();
      toast.success('藥品資料下載完成');
    } catch (error) {
      console.error('Error in initial download:', error);
      toast.error('初始資料下載失敗');
    } finally {
      setIsLoading(false); // 確保在完成或失敗後都會解除loading狀態
    }
  };

  // Load frequent medicines on mount
  useEffect(() => {
    loadFrequentMedicinesFromFirebase();
  }, [clinicId, doctorId]);

  useEffect(() => {
    const loadCustomMedicines = async () => {
      if (!clinicId) return;

      try {
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const customMedicines = clinicData.customMedicines || [];

          // If we have custom medicines and Fuse is initialized
          if (customMedicines.length > 0 && fuseRef.current) {
            // Add custom medicines to Fuse index
            customMedicines.forEach(medicine => {
              fuseRef.current.add(medicine);
            });
          }
        }
      } catch (error) {
        console.error('Error loading custom medicines:', error);
      }
    };

    if (!isLoading && localDBRef.current) {
      loadCustomMedicines();
    }
  }, [clinicId, isLoading, localDBRef.current]);

  // Add scroll detection
  useEffect(() => {
    if (suggestionsRef.current) {
      setIsScrollable(
        suggestionsRef.current.scrollHeight > suggestionsRef.current.clientHeight
      );
    }
  }, [suggestions]);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedItemRef.current && suggestionsRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedIndex]);

  // Firebase Interaction Methods
  const loadFrequentMedicinesFromFirebase = async () => {
    if (!clinicId || !doctorId) return;

    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists()) {
        const clinicData = clinicDoc.data();
        const doctors = clinicData.doctors || [];
        const currentDoctor = doctors.find(d => d.doctorId === doctorId);

        if (currentDoctor?.frequentMedicines) {
          setFrequentMedicines(currentDoctor.frequentMedicines);
        } else {
          const localMedicines = localStorage.getItem(`frequentMedicines_${doctorId}`);
          if (localMedicines) {
            setFrequentMedicines(JSON.parse(localMedicines));
          }
        }
      }
    } catch (error) {
      console.error('Error loading frequent medicines:', error);
      const localMedicines = localStorage.getItem(`frequentMedicines_${doctorId}`);
      if (localMedicines) {
        setFrequentMedicines(JSON.parse(localMedicines));
      }
    }
  };

  const updateFrequentMedicines = async (medicine) => {
    if (!clinicId || !doctorId) {
      console.warn('Missing clinicId or doctorId for frequent medicines update');
      return;
    }

    try {
      // Create a sanitized medicine object without undefined values
      const sanitizedMedicine = {
        id: medicine.id || '',
        englishName: medicine.englishName || '',
        chineseName: medicine.chineseName || '',
        spec: medicine.spec || null,
        price: medicine.price || ''
      };

      // Create new array with sanitized medicines
      const newFrequentMedicines = [sanitizedMedicine, ...frequentMedicines]
        .filter((med, index, self) =>
          index === self.findIndex(m => m.id === med.id)
        )
        .slice(0, 20)
        .map(med => ({
          id: med.id || '',
          englishName: med.englishName || '',
          chineseName: med.chineseName || '',
          spec: med.spec || null,
          price: med.price || ''
        }));

      setFrequentMedicines(newFrequentMedicines);
      localStorage.setItem(
        `frequentMedicines_${doctorId}`,
        JSON.stringify(newFrequentMedicines)
      );

      // Update Firestore
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists()) {
        const clinicData = clinicDoc.data();
        const doctors = clinicData.doctors || [];
        const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

        if (doctorIndex !== -1) {
          const updatedDoctors = [...doctors];
          updatedDoctors[doctorIndex] = {
            ...updatedDoctors[doctorIndex],
            frequentMedicines: newFrequentMedicines
          };

          await updateDoc(clinicRef, {
            doctors: updatedDoctors
          });
        }
      }
    } catch (error) {
      console.error('Error updating frequent medicines:', error);
      toast.error('更新常用藥品失敗');
    }
  };

  // Database Management Methods
  const downloadAndProcessMedicines = async () => {
    try {
      const storageRef = ref(storage, 'medicine-data/health-insurance-medicines.csv');
      const url = await getDownloadURL(storageRef);
      const metadata = await getMetadata(storageRef);

      const response = await fetch(url);
      const text = await response.text();

      const results = Papa.parse(text, {
        header: true,
        skipEmptyLines: true
      });

      const medicines = results.data.map(row => ({
        id: row.藥品代號,
        chineseName: row.藥品中文名稱,
        englishName: row.藥品英文名稱,
        spec: row.規格量,
        specUnit: row.規格單位,
        prep: row.單複方,
        dateStart: row.有效起日,
        dateEnd: row.有效迄日,
        brand: row.製造廠名稱,
        dose: row.劑型,
        ingredients: row.成份,
        price: row.參考價,
      }));

      await saveMedicinesToDB(medicines, metadata.customMetadata);
      initializeFuse(medicines);

      return medicines;
    } catch (error) {
      console.error('Error downloading medicines:', error);
      throw error;
    }
  };

  const saveMedicinesToDB = async (medicines, metadata) => {
    const BATCH_SIZE = 1000;
    const totalBatches = Math.ceil(medicines.length / BATCH_SIZE);

    try {
      const clearTransaction = localDBRef.current.transaction(['medicines'], 'readwrite');
      const store = clearTransaction.objectStore('medicines');
      await new Promise((resolve, reject) => {
        const clearRequest = store.clear();
        clearRequest.onsuccess = () => resolve();
        clearRequest.onerror = () => reject(clearRequest.error);
      });

      for (let i = 0; i < totalBatches; i++) {
        const start = i * BATCH_SIZE;
        const end = Math.min(start + BATCH_SIZE, medicines.length);
        const batch = medicines.slice(start, end);

        await new Promise((resolve, reject) => {
          const batchTransaction = localDBRef.current.transaction(['medicines'], 'readwrite');
          const store = batchTransaction.objectStore('medicines');

          batchTransaction.oncomplete = () => resolve();
          batchTransaction.onerror = (event) => reject(event.target.error);

          batch.forEach(medicine => {
            store.put({
              ...medicine,
              updatedAt: new Date().toISOString()
            });
          });
        });

        setDbStatus(`已儲存 ${end} / ${medicines.length} 筆資料`);
      }

      await new Promise((resolve, reject) => {
        const metaTransaction = localDBRef.current.transaction(['metadata'], 'readwrite');
        const metaStore = metaTransaction.objectStore('metadata');

        metaTransaction.oncomplete = () => resolve();
        metaTransaction.onerror = (event) => reject(event.target.error);

        metaStore.put({
          key: 'version',
          checksum: metadata.fileChecksum,
          updatedAt: new Date().toISOString(),
          totalRecords: medicines.length
        });
      });
    } catch (error) {
      console.error('Error saving medicines to DB:', error);
      throw error;
    }
  };

  // Get local metadata from IndexedDB
  const getLocalMetadata = async () => {
    return new Promise((resolve, reject) => {
      const transaction = localDBRef.current.transaction(['metadata'], 'readonly');
      const store = transaction.objectStore('metadata');
      const request = store.get('version');

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  };

  // Load medicines from local DB
  const loadLocalMedicines = async () => {
    return new Promise((resolve, reject) => {
      const transaction = localDBRef.current.transaction(['medicines'], 'readonly');
      const store = transaction.objectStore('medicines');
      const request = store.getAll();

      request.onsuccess = () => {
        initializeFuse(request.result);
        const lastUpdateCheck = localStorage.getItem('lastUpdateCheck');
        const lastUpdateTime = lastUpdateCheck ? new Date(parseInt(lastUpdateCheck, 10)).toLocaleString() : '無記錄';
        setDbStatus(`資料最後更新時間：${lastUpdateTime}`);
        resolve(request.result);
      };
      request.onerror = () => reject(request.error);
    });
  };

  const checkForUpdates = async () => {
    try {
      const lastUpdateCheck = localStorage.getItem('lastUpdateCheck');
      const currentTime = Date.now();

      // If no last check or more than 24 hours have passed
      if (!lastUpdateCheck || (currentTime - parseInt(lastUpdateCheck, 10)) > 24 * 60 * 60 * 1000) {
        setDbStatus('檢查更新中...');

        const storageRef = ref(storage, 'medicine-data/health-insurance-medicines.csv');
        const metadata = await getMetadata(storageRef);
        const localMetadata = await getLocalMetadata();

        if (!localMetadata || localMetadata.checksum !== metadata.customMetadata.fileChecksum) {
          setDbStatus('發現新版本，更新中...');
          await downloadAndProcessMedicines();
          toast.success('藥品資料已更新');
        } else {
          setDbStatus('資料已是最新版本');
          await loadLocalMedicines();
        }

        localStorage.setItem('lastUpdateCheck', currentTime.toString());
      } else {
        setDbStatus('載入本地資料...');
        await loadLocalMedicines();
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
      toast.error('檢查更新失敗，使用本地資料');
      await loadLocalMedicines();
    } finally {
      setIsLoading(false);
    }
  };

  const refreshDatabase = async () => {
    if (isRefreshing) return;

    try {
      setIsRefreshing(true);
      setDbStatus('正在更新資料...');
      await downloadAndProcessMedicines();
      toast.success('藥品資料庫已更新');
    } catch (error) {
      console.error('Error refreshing database:', error);
      toast.error('更新資料庫失敗');
    } finally {
      setIsRefreshing(false);
    }
  };

  // Search and Selection Methods
  const initializeFuse = (medicines) => {
    fuseRef.current = new Fuse(medicines, {
      keys: ['id', 'englishName', 'chineseName'],
      threshold: 0.3,
      includeScore: true,
      findAllMatches: true,
      ignoreLocation: true,
      // Add useExtendedSearch option to better handle custom data format differences
      useExtendedSearch: true
    });

    // If we have a clinic, also load custom medicines into the index
    if (clinicId) {
      loadCustomMedicinesIntoFuse();
    }
  };

  // Add this helper function
  const loadCustomMedicinesIntoFuse = async () => {
    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists() && fuseRef.current) {
        const clinicData = clinicDoc.data();
        const customMedicines = clinicData.customMedicines || [];

        // Add each custom medicine to the Fuse index
        customMedicines.forEach(medicine => {
          fuseRef.current.add(medicine);
        });
      }
    } catch (error) {
      console.error('Error loading custom medicines into search index:', error);
    }
  };

  const handleSearch = debounce((term) => {
    if (!term) {
      setSuggestions(frequentMedicines.slice(0, 10));
      return;
    }

    if (fuseRef.current) {
      // Get results from Fuse search
      const results = fuseRef.current.search(term)
        .slice(0, 10)
        .map(result => result.item);

      // If there are few results and we have clinicId, add custom search
      if (results.length < 5 && clinicId) {
        // Try to get custom medicines asynchronously
        const loadCustomSearchResults = async () => {
          try {
            const clinicRef = doc(db, 'OwnedClinic', clinicId);
            const clinicDoc = await getDoc(clinicRef);

            if (clinicDoc.exists()) {
              const clinicData = clinicDoc.data();
              const customMedicines = clinicData.customMedicines || [];

              // Filter custom medicines by search term
              const filteredCustom = customMedicines.filter(medicine =>
                medicine.id.toLowerCase().includes(term.toLowerCase()) ||
                medicine.englishName.toLowerCase().includes(term.toLowerCase()) ||
                medicine.chineseName.toLowerCase().includes(term.toLowerCase())
              );

              // Merge results, prioritizing custom medicines
              if (filteredCustom.length > 0) {
                const combinedResults = [...filteredCustom, ...results]
                  // Remove duplicates by ID
                  .filter((medicine, index, self) =>
                    index === self.findIndex(m => m.id === medicine.id)
                  )
                  .slice(0, 10);

                setSuggestions(combinedResults);
              } else {
                setSuggestions(results);
              }
            } else {
              setSuggestions(results);
            }
          } catch (error) {
            console.error('Error in custom medicine search:', error);
            setSuggestions(results);
          }
        };

        loadCustomSearchResults();
      } else {
        setSuggestions(results);
      }

      setSelectedIndex(0);
    }
  }, 150);

  const handleSelect = async (medicine) => {
    if (!medicine) return;

    try {
      const newMedication = {
        id: medicine.id || '',
        englishName: medicine.englishName || '',
        chineseName: medicine.chineseName || '',
        dosage: '1顆',
        frequency: '每天3次',
        duration: '3天',
        paymentType: medicine.paymentType || '健保',
        total: '9',
        spec: medicine.spec || null,
        specUnit: medicine.specUnit || '',
        prep: medicine.prep || '',
        brand: medicine.brand || '',
        price: medicine.price || '',
        dose: medicine.dose || '',
        ingredients: medicine.ingredients || '',
        customDosage: false,
        customFrequency: false,
        customDuration: false,
        prescriptionType: activeTab === 'chronic' ? 'chronic' : 'general', // Add prescription type based on active tab
      };

      if (!clinicId || !doctorId) {
        console.warn('Missing clinicId or doctorId for frequent medicines update');
      } else {
        await updateFrequentMedicines(medicine);
      }
      onChange?.([...medications, newMedication]);
      setManualInput(''); // Clear the input
      setSuggestions(frequentMedicines.slice(0, 10)); // Reset suggestions to frequent medicines
      setShowSuggestions(true); // Keep suggestions visible

      // Maintain focus on the input field
      inputRef.current?.focus();

      toast.success(`已新增: ${medicine.chineseName}`);
    } catch (error) {
      console.error('Error selecting medicine:', error);
      toast.error('新增藥品失敗');
    }
  };

  // UI Interaction Methods
  const handleFocus = () => {
    onDropdownOpen();
    if (!manualInput && frequentMedicines.length > 0) {
      setShowFrequentMedicines(true);
      setSuggestions(frequentMedicines.slice(0, 10));
    }
    setShowSuggestions(true);
  };

  const handleCloseSuggestions = () => {
    onDropdownClose();
    setShowSuggestions(false);
    setShowFrequentMedicines(false);
    setManualInput('');
    setSuggestions([]);
  };

  const handleKeyDown = (e) => {
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (suggestions[selectedIndex]) {
          handleSelect(suggestions[selectedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        handleCloseSuggestions();
        break;
      default:
        // For any other key, we ensure suggestions remain visible
        if (!showSuggestions) {
          setShowSuggestions(true);
        }
        break;
    }
  };

  // Medication Management Methods
  const calculateTotal = (dosage, frequency, duration) => {
    try {
      const dosageMatch = dosage.match(/[\d.]+/);
      const frequencyMatch = frequency.match(/[\d.]+/);
      const durationMatch = duration.match(/[\d.]+/);

      if (dosageMatch && frequencyMatch && durationMatch) {
        const total = parseFloat(dosageMatch[0]) *
          parseFloat(frequencyMatch[0]) *
          parseFloat(durationMatch[0]);
        return total.toString();
      }
    } catch (error) {
      console.error('Error calculating total:', error);
    }
    return '待定';
  };

  const updateMedication = (index, field, value, isCustom = false) => {
    const updated = [...medications];
    const updatedMedication = {
      ...updated[index],
      [field]: value,
      [`custom${field.charAt(0).toUpperCase() + field.slice(1)}`]: isCustom
    };

    if (['dosage', 'frequency', 'duration'].includes(field)) {
      updatedMedication.total = calculateTotal(
        updatedMedication.dosage,
        updatedMedication.frequency,
        updatedMedication.duration
      );
    }

    updated[index] = updatedMedication;
    onChange?.(updated);
  };

  const removeMedication = (index) => {
    onChange?.(medications.filter((_, i) => i !== index));
  };

  const handleTemplateSelect = (templateMedications) => {
    // When applying a template, preserve the prescription type
    const updatedMedications = templateMedications.map(med => ({
      ...med,
      prescriptionType: activeTab === 'chronic' ? 'chronic' : 'general'
    }));

    onChange?.(updatedMedications);
    toast.success('已套用模板');
  };

  // Helper method to filter medications by prescription type
  const getFilteredMedications = () => {
    return medications.filter(med =>
      activeTab === 'chronic'
        ? med.prescriptionType === 'chronic'
        : med.prescriptionType === 'general' || !med.prescriptionType
    );
  };

  // UI Rendering Methods
  const renderInputField = (medication, index, field, options, placeholder) => {
    const isCustom = medication[`custom${field.charAt(0).toUpperCase() + field.slice(1)}`];
    const fieldValue = medication[field];

    return (
      <div className="relative">
        {isCustom ? (
          <>
            <input
              type="text"
              value={fieldValue}
              onChange={(e) => updateMedication(index, field, e.target.value, true)}
              placeholder={placeholder}
              className="w-full h-8 px-2 text-sm border border-gray-300 rounded focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={() => updateMedication(index, field, options[0], false)}
              title="切換到選單模式"
              className="absolute right-1 top-1/2 transform -translate-y-1/2 text-xs text-gray-500 hover:text-gray-700"
            >
              ⇄
            </button>
          </>
        ) : (
          <>
            <select
              value={fieldValue}
              onChange={(e) => updateMedication(index, field, e.target.value, false)}
              className="w-full h-8 px-2 text-sm border border-gray-300 rounded focus:border-blue-500 focus:ring-1 focus:ring-blue-500 appearance-none"
            >
              {options.map((option, i) => (
                <option key={i} value={option}>{option}</option>
              ))}
            </select>
            <button
              type="button"
              onClick={() => updateMedication(index, field, '', true)}
              title="切換到手動輸入"
              className="absolute right-1 top-1/2 transform -translate-y-1/2 text-xs text-gray-500 hover:text-gray-700"
            >
              ⇄
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {/* Search Input Section */}
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <div className="relative">
            <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              ref={inputRef}
              type="text"
              value={manualInput}
              onChange={(e) => {
                setManualInput(e.target.value);
                handleSearch(e.target.value);
              }}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder="搜尋藥品名稱或代碼..."
              className="w-full pl-10 pr-20 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              disabled={isLoading}
            />
            {(showSuggestions || showFrequentMedicines) && (
              <button
                onClick={handleCloseSuggestions}
                className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <FiX className="w-4 h-4" />
              </button>
            )}
            <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-sm">
              {dbStatus}
            </div>
          </div>
        </div>
        <button
          onClick={refreshDatabase}
          disabled={isRefreshing || isLoading}
          className={`p-2 rounded-lg border border-gray-300 hover:bg-gray-50 
                     ${(isRefreshing || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          title="更新藥品資料庫"
        >
          <FiRefreshCw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
        </button>
      </div>

      {/* Suggestions Section */}
      {showSuggestions && suggestions.length > 0 && (
        <div className="relative">
          <button
            onClick={handleCloseSuggestions}
            className="absolute -top-2 -right-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
            title="關閉建議"
          >
            <FiX className="w-4 h-4 text-gray-600" />
          </button>
          <div
            ref={suggestionsRef}
            className="absolute z-40 w-full mt-1 bg-white border border-gray-200 
                     rounded-lg shadow-lg max-h-60 overflow-y-auto 
                     scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
            style={{ zIndex: zIndex }}
          >
            {showFrequentMedicines && (
              <div className="px-4 py-2 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                <span className="text-sm font-medium text-gray-600">最近使用的藥品</span>
                <button
                  onClick={() => setShowFrequentMedicines(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <FiX className="w-4 h-4" />
                </button>
              </div>
            )}

            {suggestions.map((medicine, index) => (
              <div
                key={medicine.id}
                ref={index === selectedIndex ? selectedItemRef : null}
                onClick={() => handleSelect(medicine)}
                className={`w-full px-4 py-2 text-left cursor-pointer hover:bg-gray-50 
                           flex flex-col border-b border-gray-100
                           ${index === selectedIndex ? 'bg-blue-50' : ''}`}
              >
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-blue-600">{medicine.id}</span>
                  <span className="text-sm text-gray-500">|</span>
                  <span className="font-medium">{medicine.englishName} {medicine.chineseName}</span>
                </div>
                {medicine.spec && (
                  <span className="text-xs text-gray-500">
                    規格: {medicine.dose}  {medicine.spec} {medicine.specUnit}  單複方: {medicine.prep} | 製造廠名稱: {medicine.brand} | 成份: {medicine.ingredients} | 參考價: ${medicine.price}
                  </span>
                )}
              </div>
            ))}
          </div>

          {/* Scroll indicator */}
          {isScrollable && (
            <div className="absolute right-0 top-1 bottom-1 w-1 z-50">
              <div className="h-full w-full bg-gray-200 rounded-r-lg">
                <div className="w-full bg-gray-400 rounded-r-lg opacity-50 
                             transition-all duration-300
                             hover:opacity-75 hover:w-1.5"
                  style={{
                    height: '30%',
                    transform: `translateY(${(selectedIndex / suggestions.length) * 70}%)`
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Template Controls */}
      <div className="flex justify-end gap-2">
        <button
          onClick={() => setShowSelectTemplateModal(true)}
          className="px-3 py-1 text-sm text-blue-600 bg-blue-50 rounded hover:bg-blue-100 transition-colors"
        >
          載入藥令模板
        </button>
        <button
          onClick={() => setShowSaveTemplateModal(true)}
          disabled={!medications.length}
          className="px-3 py-1 text-sm text-green-600 bg-green-50 rounded hover:bg-green-100 transition-colors disabled:opacity-50"
        >
          儲存為藥令模板
        </button>
      </div>

      {/* Tab Interface */}
      <div className="flex items-center border-b border-gray-200">
        <button
          className={`px-4 py-2 font-medium ${activeTab === 'general'
            ? 'border-b-2 border-blue-500 text-blue-500'
            : 'text-gray-500 hover:text-gray-700'
            }`}
          onClick={() => setActiveTab('general')}
        >
          一般門診
        </button>
        <button
          className={`px-4 py-2 font-medium ${activeTab === 'chronic'
            ? 'border-b-2 border-blue-500 text-blue-500'
            : 'text-gray-500 hover:text-gray-700'
            }`}
          onClick={() => setActiveTab('chronic')}
        >
          慢性處方簽
        </button>
      </div>

      {/* Refill count for chronic prescription (shown only when chronic tab is active) */}
      {activeTab === 'chronic' && (
        <div className="mt-2">
          <label className="block text-sm font-medium text-gray-700">調劑次數</label>
          <select
            value={refillCount}
            onChange={(e) => onRefillCountChange(e.target.value)}
            className="mt-1 w-40 p-2 text-sm border border-gray-300 rounded focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          >
            {REFILL_OPTIONS.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
      )}

      {/* Filtered Medications List Section */}
      <div className="space-y-2">
        {getFilteredMedications().map((med, index) => {
          // Find the original index in the full medications array
          const originalIndex = medications.findIndex(m => m === med);

          return (
            <div key={`${med.id}-${originalIndex}`} className="flex flex-col gap-2 p-3 bg-white border border-gray-200 rounded-lg">
              {/* Medicine header */}
              <div className="flex justify-between items-start w-full">
                <div className="flex flex-col flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-blue-600 text-sm">{med.id}</span>
                    <span className="text-sm text-gray-400">|</span>
                    <span className="font-medium text-sm truncate">{med.englishName} {med.chineseName}</span>
                  </div>
                </div>
                <button
                  onClick={() => removeMedication(originalIndex)}
                  className="p-1 text-red-500 hover:text-red-700"
                >
                  <FiX className="w-4 h-4" />
                </button>
              </div>

              {/* Medicine details */}
              <div className="grid grid-cols-5 gap-2">
                <div>
                  {renderInputField(med, originalIndex, 'dosage', DOSAGE_OPTIONS, '用量')}
                </div>
                <div>
                  {renderInputField(med, originalIndex, 'frequency', FREQUENCY_OPTIONS, '頻率')}
                </div>
                <div>
                  {renderInputField(med, originalIndex, 'duration', DURATION_OPTIONS, '天數')}
                </div>
                <div>
                  {renderInputField(med, originalIndex, 'paymentType', PAYMENT_OPTIONS, '給付')}
                </div>
                <div>
                  <label className="block text-xs text-gray-500">總量</label>
                  <span className="text-blue-600 font-medium px-2">
                    {med.total}
                  </span>
                </div>
              </div>

              {/* Medicine specs and price */}
              {(med.spec || med.price) && (
                <div className="text-xs text-gray-500 flex gap-4">
                  {med.spec && (
                    <span>規格: {med.spec} {med.specUnit} {med.dose}</span>
                  )}
                  {med.price && (
                    <span>參考價: ${med.price}</span>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Show message when no medications in current tab */}
      {getFilteredMedications().length === 0 && (
        <div className="p-4 text-center text-gray-500 bg-gray-50 rounded-lg">
          {activeTab === 'general'
            ? '尚未新增一般門診藥品'
            : '尚未新增慢性處方簽藥品'}
        </div>
      )}

      {/* Template Modals */}
      {showSaveTemplateModal && (
        <SaveMedicineTemplateModal
          isOpen={showSaveTemplateModal}
          closeModal={() => setShowSaveTemplateModal(false)}
          medications={medications}
          clinicId={clinicId}
          doctorId={doctorId}
        />
      )}
      {showSelectTemplateModal && (
        <SelectMedicineTemplateModal
          isOpen={showSelectTemplateModal}
          closeModal={() => setShowSelectTemplateModal(false)}
          onSelect={handleTemplateSelect}
          clinicId={clinicId}
          doctorId={doctorId}
        />
      )}
    </div>
  );
};

export default MedicineSearch;